import { Skeleton } from 'antd';
import cn from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { scrollToTop } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import useNavigation from 'src/hooks/use-navigation';
import { resetSectionLoading } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { setSelectedProvider } from 'src/store/games/actions';
import { NavigationItem } from 'src/types/res-dto/categories-res';
import { RootState } from 'src/types/store-types';
import { NavDropdown } from './components/NavDropdown/NavDropdown';
import { NavItem } from './components/NavItem/NavItem';
import './styles.scss';

type Props = {
  settings: {
    view: {
      type: 'withIcon' | 'withIcon&Divider'; // onlyText
      placement: 'left' | 'right' | 'top' | 'bottom';
    };
    styles: Styles;
    needLogIn: boolean;
    priority: number;
  };
};
export const Navigation: FC<Props> = ({ settings }) => {
  const navigationManager = useNavigation();

  const menuRef = useRef<HTMLDivElement | null>(null);

  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);
  const _resetSectionLoading = useConnectedAction(resetSectionLoading);

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { generalConfigs, navigation } = useSelector((state: RootState) => state.configs);
  const { user } = useSelector((state: RootState) => state.user);
  const { selectedProvider } = useSelector((state: RootState) => state.games);

  const [menuItems, setMenuItems] = useState<NavigationItem[] | any[]>([]);
  const [dropdownItems, setDropdownItems] = useState<NavigationItem[] | any[]>([]);
  const url = localStorage.getItem('url') as string;

  const changeNavigation = (navigation: NavigationItem): void => {
    _resetSectionLoading();

    if (navigation.name === selectedCategory) return;

    if (navigation.needLogIn && !user) {
      window.location.href = url;
      _openAuthModal('login');
    } else {
      if (selectedProvider && selectedProvider[0] !== 'all_providers') {
        _setSelectedProvider(['all_providers']);
      }

      navigationManager.callNavigationAction(navigation, navigation.type);
    }

    setTimeout(() => {
      scrollToTop();
    }, 0);
  };

  useEffect(() => {
    const navWidth = Number(menuRef?.current?.clientWidth);

    if (navigation.length > 0 && navWidth) {
      const _menuItems = navigation?.filter((n) => n.showInNavigation);

      if (_menuItems?.length > 0 && generalConfigs?.navItemWidth) {
        const menuItemsCount = Math.floor(navWidth / generalConfigs?.navItemWidth);

        if (_menuItems?.length > menuItemsCount) {
          setMenuItems([..._menuItems.slice(0, menuItemsCount)]);
          setDropdownItems(_menuItems.slice(menuItemsCount));
        } else {
          setMenuItems(_menuItems);
        }
      }
    }
  }, [navigation, menuRef.current?.clientWidth, generalConfigs?.navItemWidth, user]);

  return (
    <div className="navigation_wrapper" style={settings.styles} ref={menuRef}>
      {menuItems?.length > 0 ? (
        <div
          className={cn('navigation-container', {
            'navigation-container-align': Boolean(dropdownItems?.length),
            'navigation-container-center': !Boolean(dropdownItems?.length),
          })}
        >
          {menuItems?.map((navItem: NavigationItem, idx: number) => (
            <NavItem
              selectedCategory={selectedCategory}
              view={settings.view}
              navItem={navItem}
              withUnderLine
              idx={idx}
              key={idx}
              menuItems={menuItems}
              changeNavigation={changeNavigation}
            />
          ))}
          {Boolean(dropdownItems.length) && (
            <NavDropdown
              selectedCategory={selectedCategory}
              view={settings.view}
              withUnderLine={false}
              menuItems={menuItems}
              changeNavigation={changeNavigation}
              items={dropdownItems}
            />
          )}
        </div>
      ) : (
        <Skeleton.Button active size="large" className="navigation_wrapper__skeleton" />
      )}
    </div>
  );
};
