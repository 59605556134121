import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError, setPassword } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';

type IProps = {
  name: string;
  value: string;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required?: boolean;
};
const Repeat_Password_Input: FC<IProps> = (props) => {
  // Hooks
  const { t }: Translation = useTranslation();

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const resetSignUpError = useResetSignUpError();
  const _setPassword = useConnectedAction(setPassword);

  // Selector
  const { errors: signUpErrors, password } = useSelector((state: RootState) => state.user);

  // YUP Validation
  const validationSchema = Yup.object().shape({
    password2: Yup.string()
      .required(t('requiredField'))
      .test('passwordCheck', t('password_must_be_a_match'), (val = '') => val === password),
  });

  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ password2: value }, { abortEarly: false });
      resetSignUpError('password2');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, password2: error.errors[0] });
      }
    }
  };

  // Actions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (signUpErrors?.password2) {
      resetSignUpError('password2');
    }
    props.onChange(e);
    // TO DO must replace and handle from one place
    _setPassword({ key: 'repeatPassword', value: e.target.value });
  };

  const handleBlur = (): void => {
    handleValidate(props.value);
  };

  return (
    <Input
      error={signUpErrors?.password2 || ''}
      {...props}
      onChange={handleOnChange}
      onBlur={handleBlur}
      id="repeat_password_input"
    />
  );
};
export default Repeat_Password_Input;
