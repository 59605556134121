/* eslint-disable sonarjs/cognitive-complexity */
import cn from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { SliderDataTransforamtionHelper } from 'src/helpers/SliderDataTransformationHelper';
import { transformBanners } from 'src/helpers/transformers';
import { detectDevice, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { CMSManager } from 'src/manager/CMSManager';
import SectionLoaderPage from 'src/pages/SectionLoaderPage';
import { getBanners } from 'src/services/dataApi';
import { gameUrlRequest, getCategoriresData } from 'src/services/gameApi';
import PersisterService from 'src/services/PersisterService';
import { setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { BannerFromCMS } from 'src/types/common-types/slider';
import { RootState } from 'src/types/store-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import CarouselSlideItem from '../Slider/BannerSlider/SlidetItem';
import styles from './styles.module.scss';

const Poker: FC = () => {
  const { t } = useTranslation();
  const { locale } = useI18n();
  const device = detectDevice();

  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);

  const { user } = useSelector((state: RootState) => state.user);

  const [banners, setBanners] = useState<any>([]);
  const [link, setLink] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const openPokerTab = (): void => {
    if (user && link) {
      if ((window as any).externalPopupLink && !(window as any).externalPopupLink?.closed) {
        (window as any).externalPopupLink?.focus();
      } else {
        if (isMobile) {
          const _: any = window.open(link, '_blank');
          (window as any).externalPopupLink = _;
        } else {
          const _: any = window.open(link, '_blank', 'width=1280,height=762');
          (window as any).externalPopupLink = _;
        }
      }
    } else {
      setBodyOverflow('unset');
      _openAuthModal('login');
    }
  };

  const getBannersreq = async (): Promise<void> => {
    const _deviceTtype = device === 'tablet' ? 'desktop' : device;
    const res: any = await getBanners(_deviceTtype, locale);

    const banersObj = transformBanners(
      CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string]?.getStaticBannersData(res.data)
    );
    let _banners = { [locale]: banersObj };
    if (PersisterService.hasPersistValue('banners')) {
      _banners = { ...PersisterService.getPersistValue('banners'), [locale]: banersObj };
    }
    PersisterService.setPersistValue('banners', _banners);
    setBanners(banersObj);
  };

  const getUrl = async (): Promise<void> => {
    try {
      const _device = device === 'tablet' ? 'desktop' : 'mobile';
      setLoading(true);
      const urlResponse: any = await getCategoriresData(_device);
      if (urlResponse.success && urlResponse?.categoriesData.poker) {
        const game = urlResponse?.categoriesData.poker.tags[0].tagGames[0];
        const lang = locale;

        const payload: any = {
          id: game.id,
          skinId: +(process.env.REACT_APP_SKIN_ID as string),
          lang,
          userId: user.id,
          device,
          lobbyUrl: window?.location?.href ? window.location.href : window.origin,
        };

        const response: any = await gameUrlRequest(payload);
        if (response.success) {
          const url: string = response.result.data.url;
          PersisterService.setPersistValue('POKER_URL', url);
          const formatCurrency = process.env.REACT_APP_FORMAT_CURRENCY ? '&showFormattedAmountWithDot=true' : '';
          setLink(url + formatCurrency);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleBeforeUnload = (): void => {
    if ((window as any).externalPopupLink) {
      (window as any).externalPopupLink?.close();
    }
  };

  const getBannersData = (): void => {
    let res: BannerFromCMS | any = null;

    if (PersisterService.hasPersistValue('banners') && PersisterService.getPersistValue('banners')?.[locale]) {
      try {
        res = PersisterService.getPersistValue('banners')?.[locale];
        const banersObj = transformBanners(res.data);
        setBanners(banersObj);
      } catch {
        // TO DO remove this line after detecting error in report
        localStorage.setItem('crashError', sessionStorage.getItem('banners') as string);
        getBannersreq();
      }
    } else {
      getBannersreq();
    }
  };

  const bannersArray = useMemo(() => {
    if (!!banners.poker) {
      const _prioritizesBanners = banners.poker.sort(
        (a: any, b: any) => b?.attributes?.priority - a?.attributes?.priority
      );
      return SliderDataTransforamtionHelper.setSliderItemObj(_prioritizesBanners, 1);
    }

    return [];
  }, [banners]);

  useEffect(() => {
    _setSelectedCategory('poker');
    if (user) {
      getUrl();
    }
  }, [user, locale]);

  useEffect(() => {
    getBannersData();
  }, [device, locale]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if ((window as any).externalPopupLink) {
    const checkClosed = setInterval(() => {
      if ((window as any).externalPopupLink?.closed) {
        clearInterval(checkClosed); // Stop checking once closed
      }
    }, 500);
  }

  return (
    <div className={styles.poker_content_wrapper} id="pokerContainer">
      {loading ? (
        <SectionLoaderPage />
      ) : (
        <div className={cn('banner_container', styles.poker_content_wrapper__banner)}>
          <Swiper
            className={cn(styles.carousel_container, 'carousel_container')}
            slidesPerView={1}
            spaceBetween={24}
            autoplay={{
              delay: 5000,
            }}
            speed={1500}
            slidesPerGroup={1}
            loop
            navigation={{ nextEl: '.arrow-right', prevEl: '.arrow-left' }}
          >
            {bannersArray?.map((carouselItemArr: any[], i: number) => {
              return (
                <SwiperSlide>
                  <div key={carouselItemArr[0] + i} className="carousel_item_container">
                    {carouselItemArr.map((carouselItemData, j) => {
                      return <CarouselSlideItem cols={12} rows={2} key={j} carouselItemData={carouselItemData} />;
                    })}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className={styles.poker_content_wrapper__actionsContainer}>
            <Button className={cn(styles.play_now_button, 'primary-filled-btn')} onClick={openPokerTab}>
              {t('playNow')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Poker;
