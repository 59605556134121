import { LiveChatWidget } from '@livechat/widget-react';
import { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setMobileLiveChatVisibility } from 'src/store/mobile/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    license: number;
  };
  placement: 'sidebar' | 'mobileFixedMenu';
};
const LiveChatButton: FC<Props> = ({ settings, placement }) => {
  const { license } = settings;

  const { t }: Translation = useTranslation();

  const _setMobileLiveChatVisibility = useConnectedAction(setMobileLiveChatVisibility);

  const { showMobileLiveChat } = useSelector((state: RootState) => state.mobile);

  const [chatVisibility, setChatVisibility] = useState<'hidden' | 'maximized'>('hidden');

  const openChat = (): void => {
    setChatVisibility('maximized');
  };

  const onVisibilityChange = (ev: any): void => {
    setChatVisibility(ev?.visibility === 'maximized' ? 'maximized' : 'hidden');
    _setMobileLiveChatVisibility(ev?.visibility !== 'hidden');
  };

  useEffect(() => {
    setChatVisibility(showMobileLiveChat ? 'maximized' : 'hidden');
  }, [showMobileLiveChat]);

  if (['sidebar'].includes(placement) || (isMobileOnly && placement === 'mobileFixedMenu')) {
    return (
      <div className="live_chat_button">
        <Button className="sidebar_section__item secondary-btn" onClick={openChat}>
          <div className="sidebar_section__item__container">
            <SvgIcon src={icons.liveChat} className={'category_icon'} />
            <span className="chat_title">{t('chat')}</span>
          </div>
        </Button>
        <LiveChatWidget
          license={license.toString()}
          visibility={chatVisibility}
          onVisibilityChanged={onVisibilityChange}
        />
      </div>
    );
  }

  return <></>;
};
export default LiveChatButton;
