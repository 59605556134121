import { fork } from 'redux-saga/effects';
import { watchApp } from './app/saga';
import { watchFirstDepositToBonus } from './bonus-messaging/saga';
import { watchConfigs } from './configs/saga';
import { watchIntegrations } from './integrations/saga';
import { watchAppMessages } from './messages/saga';
import { watchTransactions } from './transactions/saga';
import { watchUser } from './user/saga';

export default function* rootSaga(): any {
  yield fork(watchApp);
  yield fork(watchConfigs);
  yield fork(watchUser);
  yield fork(watchAppMessages);
  yield fork(watchTransactions);
  yield fork(watchIntegrations);
  yield fork(watchFirstDepositToBonus);
}
