import React from 'react';
import Input from './components/paymentMethods/Input';
import RadioInput from './components/paymentMethods/RadioGroup';
import Select from './components/paymentMethods/Select';

export class Constructor {
  public static PaymentComponents = {
    string: Input,
    number: Input,
    radiogroup: RadioInput,
    dropdown: Select,
    array: Select,
    requestList: Select,
    date: Input,
  };

  public static renderInputs = (
    payment: PaymentMethod,
    handleFieldChange: (value: any, field: string) => void,
    t: (arg: string) => string,
    paymentError: { [keys: string]: string } | null,
    setPaymentError: (error: any) => void,
    paymentType: string
  ): JSX.Element[] => {
    const keys = payment?.fields ? Object.keys(payment?.fields) : {};

    return Object.values(payment?.fields).map((field: any, i: number) => {
      const component = Constructor.PaymentComponents[payment?.fields[keys[i]]?.type];
      const _fieldKey = typeof field === 'object' && field ? Object.keys(field)[0] : field;

      return React.createElement(component, {
        data: {
          payment: payment,
          paymentType: paymentType,
          onchange: handleFieldChange,
          paymentField: keys[i],
          // TO DO need to delete t from func
          t: t,
          error: paymentError,
          setError: setPaymentError,
        },
        key: `${_fieldKey}_${i}`,
      });
    });
  };

  public static renderAdditionalInputs = (
    fields: { [key: string]: any }[],
    handleFieldChange: (value: any, field: string) => void,
    // TO DO need to delete t from func
    t: (arg: string) => string,
    paymentError: { [keys: string]: string } | null,
    setPaymentError: (error: any) => void,
    selectedPaymentId: number
  ): JSX.Element[] => {
    const keys = Object.keys(fields[0]);

    return Object.values(fields).map((field: any, i: number) => {
      const component = Constructor.PaymentComponents[fields[0][keys[i]]?.type];
      const _fieldKey = typeof field === 'object' && field ? Object.keys(field)[0] : field;
      return React.createElement(component, {
        data: {
          payment: { fields: fields[0] },
          onchange: handleFieldChange,
          paymentField: keys[i],
          t: t,
          error: paymentError,
          setError: setPaymentError,
        },
        key: `${_fieldKey}_${i}_${selectedPaymentId}`,
      });
    });
  };
}
