import cn from 'classnames';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'src/components/common/Typography';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { forgotPasswordRequest } from 'src/services/authorizationApi';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { forgotPasswordValidationScheme } from '../../../../../../helpers/validationScheme';
import { IForgotRes } from '../../../../../../types/res-dto/authorization';
import { Button } from '../../../../../common/Button';
import { Input } from '../../../../../common/Input';
import _styles from './styles.module.scss';

interface IProps extends IForgotRes {
  close: () => void;
  setContent: (action: 'open' | 'close', content: 'login' | 'registration' | 'forgot' | null) => void;
}

type Form = {
  email: string;
  username: string;
};

const ForgotPassContent: FC<IProps> = ({ close, username, email }) => {
  const { t }: Translation = useTranslation();

  const _openDialog = useConnectedAction(openDialog);

  const [generalError, setGeneralError] = useState<string>('');

  const formik = useFormik<Form>({
    validationSchema: forgotPasswordValidationScheme(),
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      email: '',
      username: '',
    },
    onSubmit: async () => {
      const res = await forgotPasswordRequest({
        email: values.email,
        username: values.username,
        skinId: +(process.env.REACT_APP_SKIN_ID as string),
      });

      if (!!res.success) {
        setGeneralError('');
        close();
        _openDialog({
          dialogType: EnumDialogsKeys.RESET_PASSWORD_SUCCESS,
          dialogProps: { email: values.email, dialogCase: 'forgotPassword' },
        });
      } else if (['document_not_found', 'unprocessable_entity'].includes(res.message?.key)) {
        setGeneralError('incorrectUsernameOrEmail');
      }
    },
  });

  const { values, getFieldProps, handleSubmit, errors, touched } = formik;

  return (
    <>
      <p className={_styles.subText}>{t('forgot_pass_text')}</p>
      <div className={_styles.forgot_password_container}>
        {Boolean(username) && (
          <Input
            {...getFieldProps('username')}
            value={values.username}
            label={'username'}
            error={(!!touched.username && !!errors.username && t(errors.username)) || ''}
          />
        )}
        {Boolean(email) && (
          <Input
            {...getFieldProps('email')}
            value={values.email}
            label={'email'}
            error={(!!touched.email && !!errors.email && t(errors.email)) || ''}
          />
        )}
      </div>
      {generalError && (
        <Typography variant={'body3'} className={_styles.invalid_error} color="error">
          {t(generalError)}
        </Typography>
      )}
      <Button className={cn(_styles.recoverButton, 'success-filled-btn')} onClick={handleSubmit}>
        {t('button_recover')}
      </Button>
    </>
  );
};

export default ForgotPassContent;
