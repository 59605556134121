import cn from 'classnames';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { getExtension, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeAccountModal } from 'src/store/account-modal/actions';
import { setGameBoxWidgetOpen, setShowUserSettings } from 'src/store/app/actions';
import { toggleMobileMenu, toggleMobileSearch } from 'src/store/mobile/actions';
import { RootState } from 'src/types/store-types';
import _styles from './styles.module.scss';

type Props = {
  settings: {
    src: string;
    styles: Styles;
    priority: number;
    needLogin: false;
  };
};
const Mobile_Menu_Button: FC<Props> = ({ settings }) => {
  const { src, styles } = settings;

  // const { t }: Translation = useTranslation();

  const _toggleMobileMenu = useConnectedAction(toggleMobileMenu);
  const _toggleMobileSearch = useConnectedAction(toggleMobileSearch);
  const _setShowUserSettings = useConnectedAction(setShowUserSettings);
  const _setGameBoxWidgetOpen = useConnectedAction(setGameBoxWidgetOpen);
  const _closeModal = useConnectedAction(closeAccountModal);

  const iframe = document?.getElementById('gamebox-widget') as HTMLIFrameElement;

  const { showMenu, showSearch } = useSelector((state: RootState) => state.mobile);
  const { showUserSetting, isGameBoxOpen } = useSelector((state: RootState) => state.app);
  const { accountModal } = useSelector((state: RootState) => state);

  const selectMenuItem = (): void => {
    setBodyOverflow(showMenu ? 'set' : 'unset');

    if (accountModal?.section) {
      _closeModal();
    }

    if (iframe && isGameBoxOpen && isMobileOnly) {
      iframe?.contentWindow?.postMessage(
        {
          type: 'CHANGE_WIDGET_VIEW',
          view: '',
        },
        '*'
      );
      _setGameBoxWidgetOpen();
    }

    _toggleMobileMenu();
    showSearch && _toggleMobileSearch();
    showUserSetting && _setShowUserSettings();
  };

  return (
    <div
      className={cn(_styles.mobile_menu_button, { [_styles.menu_open]: showMenu })}
      onClick={selectMenuItem}
      style={styles}
    >
      {getExtension(src) === 'svg' ? (
        <SvgIcon src={`/images/icons/header/${src}`} styles={styles} />
      ) : (
        <img src={`/images/icons/header/${src}`} style={styles} alt="Logo" />
      )}
      {/* TO DO get info from config */}
      {/* <span>{t('menu')}</span> */}
    </div>
  );
};
export default Mobile_Menu_Button;
