import { isDesktop, isMobileOnly } from 'react-device-detect';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getGeneralConfigs, getNavigation, getPageConfigs } from 'src/services/configsApi';
import { gameUrlRequest, getCategoriresData } from 'src/services/gameApi';
import PersisterService from 'src/services/PersisterService';
import { GAMES_COUNT_PER_PAGE_DESKTOP, GAMES_COUNT_PER_PAGE_MOBILE } from 'src/utils/constants';
import { setSectionLoading } from '../app/actions';
import { setCategoriesData, setConfigs, setGeneralConfigs, setPageConfigs, setPalette } from './actions';
import {
  CONFIGS_SET_CATEGORIES_DATA_REQ,
  CONFIGS_SET_GENERAL_CONFIGS_REQ,
  CONFIGS_SET_PAGE_CONFIGS_REQ,
  CONFIGS_SET_PALETTE_REQ,
  CONFIGS_SET_SETTINGS_REQ,
} from './types';

function* setPaletteSaga({ payload }: any): any {
  const { onSuccessCb, onFailCb, palette } = payload;

  try {
    yield put(setPalette.success({ palette: palette }));

    if (onSuccessCb) {
      onSuccessCb();
    }
  } catch (error: any) {
    if (onFailCb) {
      onFailCb();
    }
  }
}

export function* setGeneralConfigsSaga(): any {
  try {
    let response = null;

    if (PersisterService.hasPersistValue('generalConfigs')) {
      response = PersisterService.getPersistValue('generalConfigs');
      yield put(setGeneralConfigs.success(response));
      localStorage.setItem('template', response.template);
    } else {
      response = yield call(getGeneralConfigs);

      if (response.success) {
        PersisterService.setPersistValue('generalConfigs', response.result);
        localStorage.setItem('template', response.result.template);

        yield put(setGeneralConfigs.success(response.result));
      }
    }
  } catch (e) {
    yield put(setGeneralConfigs.fail());
  }
}

//page configs
function* getPageConfigsSaga(): any {
  try {
    let res = null;
    if (PersisterService.hasPersistValue('pageConfigs')) {
      res = PersisterService.getPersistValue('pageConfigs');
      yield put(setPageConfigs.success(res));
    } else {
      const _desktop = isDesktop ? 'desktop' : 'tablet';
      res = yield call(getPageConfigs, isMobileOnly ? 'mobile' : _desktop);

      PersisterService.setPersistValue('pageConfigs', res.result);
      yield put(setPageConfigs.success(res.result));
    }
  } catch (error) {
    yield put(setPageConfigs.fail());
  }
}

// get application header configurations
function* setHeaderConfigsSaga({ payload }: any): any {
  const { onSuccessCb, onFailCb, settings } = payload;

  try {
    yield put(setConfigs.success({ settings }));

    if (onSuccessCb) {
      onSuccessCb();
    }
  } catch (error: any) {
    if (onFailCb) {
      onFailCb();
    }
  }
}
// get categories data /tags/providers
// eslint-disable-next-line sonarjs/cognitive-complexity
function* setCatgoriesDataSaga(): any {
  try {
    const response = yield call(
      getCategoriresData,
      isMobileOnly ? 'mobile' : 'desktop',
      isMobileOnly ? GAMES_COUNT_PER_PAGE_MOBILE : GAMES_COUNT_PER_PAGE_DESKTOP
    );

    if (response?.categoriesData.casinoTournament) {
      const game = response?.categoriesData.casinoTournament.tags[0].tagGames[0];
      const payload: any = {
        id: game.id,
        skinId: +(process.env.REACT_APP_SKIN_ID as string),
        lang: localStorage.getItem('selectedLanguage') || '',
        device: isMobileOnly ? 'mobile' : 'desktop',
      };
      const gameUrlResponse = yield call(gameUrlRequest, payload);
      if (gameUrlResponse.success) {
        const GOS_URL = gameUrlResponse.result.data.url.split('/?')[0];
        sessionStorage.setItem('GOS_URL', GOS_URL);
      }
    }

    if (process.env.REACT_APP_CONFIGS_MODE === 'MOCK') {
      const navigation = yield call(getNavigation);
      PersisterService.setPersistValue('navigation', navigation.result);
      yield put(
        setCategoriesData.success({
          categoriesData: response.categoriesData,
          navigation: navigation.result,
          tagsMetaInfo: response.tagsMetaInfo,
          pageTagsPreviews: response.pageTagsPreviews,
        })
      );
      yield put(setSectionLoading(true));
    } else {
      yield put(setCategoriesData.success(response));
      yield put(setSectionLoading(false));
    }
  } catch (error: any) {
    yield put(setSectionLoading(false));
  }
}

export function* watchConfigs(): any {
  yield takeLatest(CONFIGS_SET_PALETTE_REQ, setPaletteSaga);
  yield takeLatest(CONFIGS_SET_GENERAL_CONFIGS_REQ, setGeneralConfigsSaga);
  yield takeLatest(CONFIGS_SET_PAGE_CONFIGS_REQ, getPageConfigsSaga);
  yield takeLatest(CONFIGS_SET_SETTINGS_REQ, setHeaderConfigsSaga);
  yield takeLatest(CONFIGS_SET_CATEGORIES_DATA_REQ, setCatgoriesDataSaga);
}
