import cn from 'classnames';
import { FC, memo, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  children: ReactNode;
  text: string;
  position: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
};

const Tooltip: FC<Props> = ({ children, text, position, className }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState<boolean>(false);

  const handleTooltipClick = (): void => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    let timer: any;

    if (show) {
      timer = setTimeout(() => {
        setShow(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [show]);

  return (
    <div className={cn('tooltip_wrapper', className)}>
      {show && (
        <span className={cn('tooltip__overlay', { [`tooltip__overlay--${position}`]: !!position })}>{t(text)}</span>
      )}
      <div onClick={handleTooltipClick} className="tooltip__inner">
        {children}
      </div>
    </div>
  );
};

export default memo(Tooltip);
