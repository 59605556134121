import { BannerFromCMS, HomeBannersFromCMS, HomePageBanner } from 'src/types/common-types/slider';
import { ArticlesRes, GamCare } from 'src/types/res-dto/page-res';
import { APP_API_VERSION, EnumLangCodes } from 'src/utils/constants';
import HttpClient from './http';

const getBanners = (device: string, locale: string): Promise<BannerFromCMS> => {
  const filters = `&filters[Device][type][$eq]=${device}&locale=${EnumLangCodes[locale]}`;

  return HttpClient.get(
    {
      path: `api/static-banners?populate=*${filters}`,
      baseURL: process.env.REACT_APP_FILE_STORAGE_ADDRESS as string,
    },
    false
  );
};

const getHomeGrid = (device: string, locale: string): Promise<HomeBannersFromCMS> => {
  const filters = `&filters[Device][type][$eq]=${device}&locale=${EnumLangCodes[locale]}`;

  return HttpClient.get(
    {
      path: `api/page-grids?populate=*${filters}`,
      baseURL: process.env.REACT_APP_FILE_STORAGE_ADDRESS as string,
    },
    false
  );
};

const getBonuses = (device: string, locale: string): Promise<any> => {
  const filters = `&filters[Device][type][$eq]=${device}&locale=${EnumLangCodes[locale]}`;

  return HttpClient.get(
    {
      path: `api/promotions?populate=*${filters}`,
      baseURL: process.env.REACT_APP_FILE_STORAGE_ADDRESS as string,
    },
    false
  );
};

const getCustomI18n = (): Promise<any> => {
  return HttpClient.get(
    {
      path: `api/custom-i18n?locale=all`,
      baseURL: process.env.REACT_APP_FILE_STORAGE_ADDRESS as string,
    },
    false
  );
};

const getSocilaLinks = (): Promise<any> => {
  return HttpClient.get(
    {
      path: `api/social-links?populate=*`,
      baseURL: process.env.REACT_APP_FILE_STORAGE_ADDRESS as string,
    },
    false
  );
};

const getHelpCenterArticles = (): Promise<ArticlesRes[]> => {
  return HttpClient.get(`/mock/staticData/articles.json`, false);
};

const getFinalTourInfo = (): Promise<HomePageBanner[]> => {
  return HttpClient.get(
    {
      path: 'api/public-popups?populate=*',
      baseURL: process.env.REACT_APP_FILE_STORAGE_ADDRESS as string,
    },
    false
  );
};

const getAuthImages = async (): Promise<HomePageBanner[]> => {
  return HttpClient.get(
    {
      path: 'api/widgets-content?populate=*',
      baseURL: process.env.REACT_APP_FILE_STORAGE_ADDRESS as string,
    },
    false
  );
};

const getPaymentsLogos = (): Promise<any> => {
  return HttpClient.get(
    {
      path: `${APP_API_VERSION}/getPaymentMethodsIcons`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    false,
    { hierarchyId: process.env.REACT_APP_SKIN_ID }
  );
};

const getGamCare = (): Promise<GamCare[]> => {
  return HttpClient.get(`/mock/staticData/gamCare.json`, false);
};

const getStaticPagesFromCms = (language: string): Promise<any> => {
  const filters = `&locale=${EnumLangCodes[language]}`;

  return HttpClient.get(
    {
      path: `api/info-page-categories?populate=*${filters}`,
      baseURL: process.env.REACT_APP_FILE_STORAGE_ADDRESS as string,
    },
    false
  );
};

const getCurrentUrl = async (): Promise<string> => {
  try {
    return await HttpClient.get('https://no2betdomains.draftplaza.com/getCurrent');
  } catch (error) {
    throw new Error('Error fetching data');
  }
};

const reportProblem = (m: any): Promise<any> => {
  const user: any = localStorage.getItem('user');
  const userInfo = user ? JSON.parse(user).id : 'Unauthorised user';
  const userAgent = window.navigator.userAgent;

  return HttpClient.post(
    {
      path: `${APP_API_VERSION}/sendWarningEmail`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    { message: `${userAgent} --->  ${userInfo} ---> ${m}` },
    false
  );
};
const sendEmail = (m: any): Promise<any> => {
  return HttpClient.post(
    {
      path: `${APP_API_VERSION}/sendWarningEmail`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    { message: `message ---> ${m}` },
    false
  );
};

export {
  getAuthImages,
  getBanners,
  getBonuses,
  getCurrentUrl,
  getCustomI18n,
  getFinalTourInfo,
  getGamCare,
  getHelpCenterArticles,
  getHomeGrid,
  getPaymentsLogos,
  getSocilaLinks,
  getStaticPagesFromCms,
  reportProblem,
  sendEmail,
};
