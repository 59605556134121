import React, { FC } from 'react';
import { isIOS } from 'react-device-detect';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundaryComponent from './hoc/ErrorBoundary';
import { I18nProvider } from './hoc/I18Provider';
import { Main } from './pages/Main';
import './styles/index.scss';

(function initGoogleAnalytics() {
  const hasGoogleAnalytics = process.env?.REACT_APP_GOOGLE_ANALYTICS;
  if (hasGoogleAnalytics) {
    ReactGA.initialize(hasGoogleAnalytics);
  }
})();

const App: FC = () => {
  const gtmId = process.env?.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  if (isIOS) {
    // Create a meta tag element
    const viewportMetaTag = document.createElement('meta');

    // Set attributes for the meta tag
    viewportMetaTag.setAttribute('name', 'viewport');
    viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');

    // Append the meta tag to the head element of the document
    document.head.appendChild(viewportMetaTag);
  }
  if (gtmId) {
    TagManager.initialize({ gtmId });
  }
  return (
    <I18nProvider>
      <ErrorBoundaryComponent>
        <Main />
      </ErrorBoundaryComponent>
    </I18nProvider>
  );
};
export default App;
