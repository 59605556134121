import { Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import './styles.scss';

interface IProps {
  doAction: () => void;
  open: boolean;
  doActivity: () => void;
}
const InactivityModal: FC<IProps> = ({ doAction, doActivity, open }) => {
  const { t }: Translation = useTranslation();

  const [counter, setCounter] = useState<number>(20);

  useEffect((): any => {
    if (counter > 0) {
      const timer: any = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    } else {
      doAction();
    }
  }, [counter]);

  return (
    <Modal footer={null} open={open} className={'inactivity-modal-container'} onCancel={doActivity}>
      <div className="inactivity-modal-container__content">
        <div className={'inactivity-modal-container__title'}>
          <Typography color="white" variant="subtitle1" fontWeight="700">
            {t('inactivityAlertTitle')}
          </Typography>
          <img src={icons.subtract} />
        </div>
        <Typography variant="subtitle3" color="white">
          {t('inactivityMessage')}
        </Typography>
        <Typography variant="subtitle3" color="white">
          {t('inactivityHelperMessage')}
        </Typography>
        <div className={'inactivity-modal-container__counter'}>
          <img src={icons.sandClock} />
          <Typography variant="subtitle3">
            00:{counter > 9 ? counter : `0${counter}`} {t('seconds')}
          </Typography>
        </div>
        <div className="inactivity-modal__button-wrapper">
          <Button className={'inactivity-modal__button success-filled-btn'} onClick={doActivity}>
            <Typography variant="subtitle3" color="white" fontWeight="700">
              {t('stayLoggedIn')}
            </Typography>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InactivityModal;
