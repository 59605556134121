import cn from 'classnames';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { isAndroid, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { setBodyOverflow } from 'src/helpers/utils';
import { useOutsideClick } from 'src/hooks/use_outside_click';
import { MediaManager } from 'src/manager/MediaManager';
import { getGamCare } from 'src/services/dataApi';
import { GamCare } from 'src/types/res-dto/page-res';
import HyperText from './components/HyperText';
import List from './components/List';
import Table from './components/Table';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
    src: string;
  };
};

const GameCare: FC<Props> = ({ settings }) => {
  const { t } = useTranslation();
  const { styles, src } = settings;

  const ref = useRef<HTMLDivElement | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<GamCare[]>([]);

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
    setBodyOverflow('unset');
  };

  const handleCloseModal = (): void => {
    setBodyOverflow('set');
    setIsModalOpen(false);
  };

  const fetchGamCareData = (): void => {
    getGamCare().then((res: any) => {
      setData(res);
    });
  };

  const renderData = useCallback(
    (data: any[]): JSX.Element[] => {
      return data?.map((item: any, index: number) => {
        switch (item.view) {
          case 'list':
            return <List key={index} listInfo={item} />;
          case 'hyperText':
            return <HyperText key={index} hyperTextInfo={item} />;
          case 'table':
            return <Table key={index} tableInfo={item} />;
          default:
            return <p key={index}>{item.value}</p>;
        }
      });
    },
    [data]
  );

  useEffect(() => {
    fetchGamCareData();
  }, []);

  useOutsideClick(ref, handleCloseModal, ['game_care_id'], null);

  return (
    <>
      <div className="gameCare_container" style={styles}>
        <img src={MediaManager.getSrcFromMediaAssets(src, '/footerIcons/')} alt="Game care" onClick={handleOpenModal} />
      </div>

      <PopUpContainer
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        hasOverlay
        type="fullSize"
        title={t('whatIsGamCare')}
        zIndex={isMobile ? 10 : 150}
        className="gameCare-body"
      >
        <div ref={ref} className="gameCare">
          <div
            className={cn('gameCare_content', {
              ['gameCare_content_android']: isAndroid && isMobile,
            })}
          >
            {renderData(data)}
          </div>
        </div>
      </PopUpContainer>
    </>
  );
};
export default GameCare;
