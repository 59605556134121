import cn from 'classnames';
import { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { formatCurrency } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useScrollWithShadow } from 'src/hooks/useScrollWithShadow';
import { getDepositConfigs } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import { accountModalState } from 'src/store/account-modal/types';
import { setToggleBonusWallet, setToggleVipPoits, toggleBalanceVisibility } from 'src/store/app/actions';
import { RootState } from 'src/types/store-types';
import _styles from './styles.module.scss';

type Section = {
  name: string;
  needLogin: boolean;
  subSections: string[];
  icon?: string;
};

interface IModalType {
  section: string;
  subSection: string;
}

type Props = {
  View: ReactElement;
  accountModal: accountModalState;
  open: (ev: IModalType) => void;
  close: () => void;
};
const DesktopAccountModal: FC<Props> = ({ View, accountModal, open, close }) => {
  const { t } = useTranslation();
  const subsectionContainer = useRef<any>();

  const _toggleBalanceVisibility = useConnectedAction(toggleBalanceVisibility);
  const _setToggleVipPoints = useConnectedAction(setToggleVipPoits);
  const _setToggleBonusWallet = useConnectedAction(setToggleBonusWallet);

  const { generalConfigs, bottomNavigation } = useSelector((state: RootState) => state.configs);
  const { user, wallets } = useSelector((state: RootState) => state.user);
  const { showMainBalance, showVipPoints, showBonusWallet } = useSelector((state: RootState) => state.app);

  const [sections, setSections] = useState<string[]>([]);
  const [subSectionsList, setSubSectionsList] = useState<Section[]>([]);
  const [subSections, setSubSections] = useState<string[]>([]);
  const [idIsCopied, setIdIsCopied] = useState<boolean>(false);

  const { onScroll } = useScrollWithShadow(subsectionContainer.current, subSections);

  const section = accountModal.section === 'info_and_rules' ? accountModal.section : accountModal.subSection;

  const getSubSection = (section: string): any[] => {
    return (subSectionsList.filter((subSections) => subSections.name === section)[0] as any)?.children ?? [];
  };

  const getSectionIcon = useCallback((section: string): JSX.Element => {
    const _section = subSectionsList.filter((subSections) => subSections?.name === section);

    if (_section[0]?.icon) {
      return <SvgIcon src={`/images/account-modal/${_section[0].icon}`} />;
    }

    return <></>;
  }, []);

  const changeSection = (section: string) => () => {
    open({
      section: section,
      subSection:
        (getSubSection(section)?.[0]?.name ? getSubSection(section)?.[0]?.name : getSubSection(section)?.[0]) ??
        section,
    });
  };

  const changeSubSection = (subSection: string) => () => {
    open({ section: accountModal.section, subSection: subSection });
  };

  const copyUserId = (): void => {
    navigator.clipboard.writeText(user.id).then(() => {
      setIdIsCopied(true);

      setTimeout(() => {
        setIdIsCopied(false);
      }, 1500);
    });
  };

  const wallet = useMemo(() => {
    const bonusWallet = wallets?.find((f: any) => f.walletType === 'bonus');
    const mainWallet = wallets?.find((f: any) => f.walletType === 'main');

    return {
      money: formatCurrency(mainWallet?.balance, mainWallet?.currency),
      points: formatCurrency(wallets?.find((f: any) => f.currency === 'GOS')?.balance, 'GOS'),
      bonus: formatCurrency(bonusWallet?.balance, bonusWallet?.currency),
      withdrawableBalance:
        mainWallet?.withdrawableBalance || mainWallet?.withdrawableBalance === 0
          ? formatCurrency(mainWallet?.withdrawableBalance, mainWallet?.currency)
          : null,
    };
  }, [wallets]);

  const getStaticPageData = (subSectionsList: Section[]): any => {
    const infoAndRules = bottomNavigation?.map((m) => ({
      name: m.name,
      title: m.title,
    }));
    const _info_and_rules = subSectionsList.find((f) => f.name === 'info_and_rules') as Section;

    if (!!_info_and_rules) {
      (_info_and_rules as any).children = infoAndRules;
      const _subSectionsList = subSectionsList.filter((f) => f.name !== 'info_and_rules');
      setSubSectionsList([..._subSectionsList, _info_and_rules]);
    }
  };

  // TO DO we must understand why this func called getDepositConf but set account modal data
  const getDepositConf = async (): Promise<void> => {
    let res = null;

    if (PersisterService.hasPersistValue('depositConfig')) {
      res = PersisterService.getPersistValue('depositConfig');

      const response = user
        ? res?.map((section: Section) => section.name)
        : res?.filter((f: any) => !f.needLogin).map((section: Section) => section.name);
      setSections(response);
      setSubSectionsList(res);
      getStaticPageData(res);
    } else {
      res = await getDepositConfigs();
      if (!!res?.result) {
        PersisterService.setPersistValue('depositConfig', res.result);

        const response = user
          ? res.result?.map((section: Section) => section.name)
          : res.result?.filter((f: any) => !f.needLogin).map((section: Section) => section.name);

        setSections(response);
        setSubSectionsList(res.result);
        getStaticPageData(res.result);
      }
    }
  };
  const scrollToSelectedSubSection = (): void => {
    const id = accountModal.subSection;

    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    getDepositConf();
  }, [user]);

  useEffect(() => {
    if (!!sections?.length) {
      const updatedSelectedSection = accountModal.section === '' ? sections[0] : accountModal.section;
      setSubSections(getSubSection(updatedSelectedSection));
    }
  }, [sections, accountModal, subSectionsList]);

  useEffect(() => {
    !!subSections && !!accountModal.subSection && scrollToSelectedSubSection();
  }, [accountModal?.subSection, subSections]);

  return (
    <PopUpContainer
      isOpen={!!accountModal.section}
      handleClose={close}
      hasOverlay
      childerHasImage={false}
      type="fullSize"
      zIndex={150}
    >
      <div className={_styles.account_modal} id="account-nodal">
        <div className={_styles.modal_container}>
          {user && (
            <div className={cn(_styles.modal_sidebar, 'account_sidebar')}>
              <div className={cn(_styles.user_section, 'account_user_section')}>
                <div className={_styles.user_settings_info}>
                  <div className={cn(_styles.user, 'user-avatar')}>
                    <SvgIcon src="/images/settings/yellowUser.svg" className={_styles.user_avatar} />
                  </div>
                  <div className={_styles.user_info}>
                    <span className={_styles.username}>{user?.nickname ?? user.username}</span>
                    <div className={_styles.userId}>
                      <span>
                        {t('id')} {user?.id}
                      </span>
                      <SvgIcon src="/images/copy.svg" className={_styles.copy} onClick={copyUserId} />
                      <span className={_styles.copied}>{idIsCopied && t('copied')}</span>
                    </div>
                  </div>
                </div>
                <div className={cn(_styles.user_settings__balance_info, 'user_balance')}>
                  <div className={_styles.balance}>
                    <span className={_styles.title}>{t('main_balance')}:</span>
                    <span className={_styles.value}>{showMainBalance ? wallet.money : '*******'}</span>
                  </div>
                  <SvgIcon
                    src={`/images/${showMainBalance ? 'opened' : 'closed'}_eye.svg`}
                    className={_styles.eye}
                    onClick={_toggleBalanceVisibility}
                  />
                </div>
                {wallet?.withdrawableBalance && (
                  <div className={cn(_styles.user_settings__withdrawableBalance_info, 'user_balance')}>
                    <Typography className="label" variant={'h5'}>
                      {t('withdrawableBalance')}
                    </Typography>
                    <Typography className="label" variant={'h4'}>
                      {wallet.withdrawableBalance}
                    </Typography>
                  </div>
                )}
                {generalConfigs?.showVipPoints && (
                  <div className={cn(_styles.user_settings__balance_info, 'user_balance')}>
                    <div className={_styles.balance}>
                      <span className={_styles.title}>{t('gos_points')}</span>
                      <span className={_styles.value}>{showVipPoints ? wallet.points : '*******'}</span>
                    </div>
                    <SvgIcon
                      src={`/images/${showVipPoints ? 'opened' : 'closed'}_eye.svg`}
                      className={_styles.eye}
                      onClick={_setToggleVipPoints}
                    />
                  </div>
                )}
                {generalConfigs?.showBonusWallet && (
                  <div className={cn(_styles.user_settings__balance_info, 'user_balance')}>
                    <div className={_styles.balance}>
                      <span className={_styles.title}>{t('bonus_balance')}</span>
                      <span className={_styles.value}>{showBonusWallet ? wallet.bonus : '*******'}</span>
                    </div>
                    <SvgIcon
                      src={`/images/${showBonusWallet ? 'opened' : 'closed'}_eye.svg`}
                      className={_styles.eye}
                      onClick={_setToggleBonusWallet}
                    />
                  </div>
                )}
              </div>
              {sections?.map((section, i) => {
                return (
                  <span
                    key={section + i}
                    className={cn(
                      _styles.section,
                      {
                        [_styles.active_section]: accountModal?.section === section,
                        active_section: accountModal?.section === section,
                      },
                      'account_section'
                    )}
                    onClick={changeSection(section)}
                  >
                    {t(section)}
                    {getSectionIcon(section)}
                  </span>
                );
              })}
            </div>
          )}
          <div
            className={cn(_styles.modal_content, [_styles[section]])}
            style={{ width: user ? `calc(76.25rem - 280px)` : '100%' }}
          >
            <div className={_styles.header}>
              <div
                className={cn(_styles.subsections, 'account_subsections')}
                onScroll={(e) => onScroll(e.target)}
                ref={subsectionContainer}
              >
                {subSections?.length > 0 ? (
                  subSections?.map((subSection: any, i) => {
                    return (
                      <span
                        id={subSection?.name ? subSection?.name : subSection}
                        key={subSection?.title ? subSection?.title + i : subSection + i}
                        className={cn({
                          [_styles.active_subSection]:
                            accountModal.subSection === subSection?.name || accountModal.subSection === subSection,
                        })}
                        onClick={changeSubSection(subSection?.name ? subSection?.name : subSection)}
                      >
                        {t(subSection?.title ? subSection?.title : subSection)}
                      </span>
                    );
                  })
                ) : (
                  <span className={_styles.active_subSection} onClick={changeSubSection(accountModal.section)}>
                    {t(accountModal.section)}
                  </span>
                )}
              </div>
            </div>
            <div className={_styles.content}>{View}</div>
          </div>
        </div>
      </div>
    </PopUpContainer>
  );
};

export default DesktopAccountModal;
