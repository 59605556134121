import { IReqPayloadStructure } from 'src/types/store-types';
import {
  ForgotPassword,
  SendOTPCodeAgain,
  SignInPayload,
  SignUpPayload,
  ValidatationCPF,
  ValidatationField,
  ValidatationUsername,
  ValidationOTP,
} from '../types/req-dto/authorization';
import { APP_API_VERSION } from '../utils/constants';
import { HttpClient } from './http';

const signInRequest = (payload: SignInPayload): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/authenticatePlayer`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const signUpRequest = (payload: SignUpPayload): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/playerRegistration`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const validateOTPRequest = (payload: ValidationOTP): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/validateOtp`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const senOTPRequestAgain = (payload: SendOTPCodeAgain): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/resendOtp`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const validateCPFRequest = (payload: ValidatationCPF): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/validateCPF`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const validateNationalIdRequest = (payload: ValidatationField): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/validateRegistrationField`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const validateUsernameRequest = (payload: ValidatationUsername): Promise<any> => {
  return HttpClient.get(
    { path: `${APP_API_VERSION}/checkUsername`, baseURL: process.env.REACT_APP_REMOTE as string },
    false,
    payload
  );
};

const forgotPasswordRequest = async (payload: ForgotPassword): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/resetPassword`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const emailVerififcation = (payload: { key: string }): Promise<any> => {
  return HttpClient.get(
    { path: `${APP_API_VERSION}/confirmEmail`, baseURL: process.env.REACT_APP_REMOTE as string },
    false,
    payload
  );
};

export {
  emailVerififcation,
  forgotPasswordRequest,
  senOTPRequestAgain,
  signInRequest,
  signUpRequest,
  validateCPFRequest,
  validateNationalIdRequest,
  validateOTPRequest,
  validateUsernameRequest,
};
