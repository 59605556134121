import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Typography } from 'src/components/common/Typography';
import { MediaManager } from 'src/manager/MediaManager';
import '../styles.scss';

interface IProps {
  toggleModal: () => void;
  modalTitle: string;
  modalContentsMap: { [key: string]: any };
  content: 'login' | 'forgot' | 'registration' | null;
}

const ContentWithoutImage: FC<IProps> = ({ content, modalTitle, modalContentsMap }) => {
  const { t }: Translation = useTranslation();

  if (isMobileOnly) {
    return (
      <div className="content_without_image_wrapper">
        <div className="auth_header">
          <img src={MediaManager.getSrcFromMediaAssets('logo-mobile.svg', '/logo/')} alt="Logo" />
        </div>
        <div className="withoutImage_body">
          {modalTitle && (
            <Typography variant={'h4'} className="auth_mobile_title">
              {t(modalTitle + '_modal_title')}
            </Typography>
          )}
          {modalContentsMap[content as string]}
        </div>
      </div>
    );
  }

  return (
    <div className="content_without_image_wrapper">
      {content !== 'forgot' && (
        <div className="without_image__logo-wrapper">
          <img src={MediaManager.getSrcFromMediaAssets('logo.svg', '/logo/')} alt="Logo" />
        </div>
      )}
      {modalTitle && (
        <Typography variant={'h4'} className="title">
          {t(modalTitle + '_modal_title')}
        </Typography>
      )}
      {modalContentsMap[content as string]}
    </div>
  );
};

export default ContentWithoutImage;
