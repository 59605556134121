import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    imageSrc: string;
    type: string;
    styles: Styles;
  };
};
const Licence: FC<Props> = ({ settings }) => {
  const { styles, type, imageSrc } = settings;

  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const licenceComonent = useMemo(() => {
    if (type === 'iframe') {
      return <iframe title="licence" src={generalConfigs?.gambleLicence ?? ''} style={styles}></iframe>;
    }

    if (type === 'image') {
      return <SvgIcon src={'/images/footer/licence.svg'} className="image_licence" />;
    }

    return (
      <a style={styles} href={generalConfigs?.gambleLicence ?? ''} target="_blank">
        <img src={imageSrc || '/images/license.png'} alt="Licence" />
      </a>
    );
  }, []);

  return <div className={'licence_wrapper'}>{licenceComonent}</div>;
};
export default Licence;
