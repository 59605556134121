import cn from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { SimpleModal } from 'src/components/common/modal/SimpleModal';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import MobileHeaderContructor from 'src/constructors/Header/MobileHeaderConstructor';
import { formatCurrency } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { MediaManager } from 'src/manager/MediaManager';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { RootState } from 'src/types/store-types';
import GenerateLink from './components/GenerateLink/GenerateLink';
import InviteAFriend from './components/GenerateLink/InviteAFriend';
import TermsAndConditions from './components/GenerateLink/TermsAndCondions';
import HowItWorks from './components/HowItWorks/HowItWorks';
import ReferredFriends from './components/ReferredFriends/ReferredFriends';
import ReferredFriendsList from './components/ReferredFriends/ReferredFriendsList';
import Reward from './components/Rewards/Reward';
import _styles from './styles.module.scss';

interface IReward {
  title: string;
  data: Reward;
}

type Props = {
  rewards: IReward[];
  friendsList: FriendsList | null;
  setShowFriendsList: (show: boolean) => void;
  setShowTermsAndConditions: (show: boolean) => void;
  showFriendsList: boolean;
  showTermsAndConditions: boolean;
  openSocilMedia: (media: string) => void;
  link: string;
  copiedLink: boolean;
  copyLink: () => void;
};

const MobileRAF: FC<Props> = ({
  rewards,
  friendsList,
  showFriendsList,
  showTermsAndConditions,
  setShowFriendsList,
  setShowTermsAndConditions,
  openSocilMedia,
  link,
  copiedLink,
  copyLink,
}) => {
  const { t } = useTranslation();

  const { generalConfigs } = useSelector((state: RootState) => state.configs);
  const { openFrom } = useSelector((state: RootState) => state.accountModal);

  const [showHowItWorks, setShowHowItWorks] = useState<boolean>(false);
  const [showLink, setShowLink] = useState<boolean>(false);

  const _openAccountModal = useConnectedAction(openMyAccountModal);

  const toggleHowItWorks = (show: boolean): void => {
    setShowHowItWorks(show);
  };

  const toggleLinkModal = (show: boolean): void => {
    setShowLink(show);
  };

  const handleClickLogo = (): void => {
    _openAccountModal({ section: '', subSection: '' });
  };

  const bonusInfo = useMemo((): any => {
    const keys: string[] = Object.keys(generalConfigs?.referAFriendBonus);
    const values: string[] = Object.values(generalConfigs?.referAFriendBonus);

    return [
      formatCurrency(+values[0], keys[0], undefined, undefined, t),
      formatCurrency(+values[1], keys[1], undefined, undefined, t),
    ];
  }, [generalConfigs?.referAFriendBonus]);

  useEffect(() => {
    document.body.setAttribute('style', 'overflow: hidden');
    return () => document.body.removeAttribute('style');
  }, []);

  return (
    <>
      {openFrom === 'menu' ? (
        <MobileHeaderContructor />
      ) : (
        <div className={_styles.fixedLogo}>
          <img
            src={MediaManager.getSrcFromMediaAssets('logo-mobile.svg', '/logo/')}
            alt="Logo"
            className="logo"
            onClick={handleClickLogo}
          />
        </div>
      )}
      <div className={cn(_styles.mobile_refer_a_friend_container, { [_styles.no_scroll]: showLink })}>
        <div className={_styles.inner_container}>
          <div className={_styles.header}>
            <SvgIcon src={'/images/refer_a_friend/leftCoins.svg'} className={_styles.leftCoin} />
            <div className={_styles.title}>
              <Typography variant={'h6'}>{t('referAFriend')}</Typography>
              <span>
                {bonusInfo[0]} + {bonusInfo[1]}
              </span>
              <Button
                className={_styles.howItWorks}
                onClick={() => toggleHowItWorks(true)}
                icon={'/images/refer_a_friend/info.svg'}
              >
                {t('refer_a_friend.how_it_works')}
              </Button>
            </div>
            <SvgIcon src={'/images/refer_a_friend/rightCoins.svg'} className={_styles.rightCoin} />
          </div>
          <div className={cn(_styles.content, { [_styles.reverse]: friendsList?.friendsCount === 10 })}>
            <div>
              <div className={_styles.ref_friend}>
                <ReferredFriends friendsList={friendsList} setShowFriendsList={setShowFriendsList} />
              </div>
              <GenerateLink
                friendsList={friendsList}
                setShowFriendsList={setShowFriendsList}
                setShowTermsAndConditions={setShowTermsAndConditions}
                openSocilMedia={openSocilMedia}
                link={link}
                copiedLink={copiedLink}
                copyLink={copyLink}
                toggleLinkModal={toggleLinkModal}
              />
            </div>
            <div className={_styles.collection_wrapper}>
              {rewards?.map((reward: IReward) => {
                return <Reward key={reward.title} reward={reward.data} title={reward.title} />;
              })}
            </div>
          </div>
        </div>
        {showFriendsList && <ReferredFriendsList friendsList={friendsList} setShowFriendsList={setShowFriendsList} />}
        {showTermsAndConditions && <TermsAndConditions setShowTermsAndConditions={setShowTermsAndConditions} />}
        {showHowItWorks && <HowItWorks closeTermsAndConditions={toggleHowItWorks} />}
        <SimpleModal isOpenCustom={showLink}>
          <InviteAFriend
            closeLinkModal={toggleLinkModal}
            openSocilMedia={openSocilMedia}
            link={link}
            copiedLink={copiedLink}
            copyLink={copyLink}
          />
        </SimpleModal>
      </div>
    </>
  );
};
export default MobileRAF;
