import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { formatCurrency } from 'src/helpers/utils';
import { getRewardsConfigs } from 'src/services/referFriend';
import { RootState } from 'src/types/store-types';
import { PRIZE_DISTRIBUTION_TABLE_CAPTIONS } from 'src/utils/constants';
import _styles from '../../styles.module.scss';

const PrizeDistribution: FC = () => {
  const { t } = useTranslation();

  const { referralLinks, wallets } = useSelector((state: RootState) => state.user);

  const [rewards, setRewards] = useState<PrizeDistribution[]>([]);

  const setPrizes = (reward: PrizeDistribution | any): JSX.Element => {
    if (reward.friendsPrize.type === 'freespins') {
      return (
        <>
          <div className={_styles.prize}>{formatCurrency(reward.friendsPrize.freeSpinsCount, 'GOS')}</div>
          <div className={_styles.prize}>
            {reward.referrerPrize?.currency?.includes('GOS')
              ? formatCurrency(+reward.referrerPrize.currency, 'GOS')
              : formatCurrency(+(reward?.referrerPrize?.currency as string), 'BRL')}
          </div>
        </>
      );
    }

    return (
      <>
        <div className={_styles.prize}>
          {reward.friendsPrize?.currency?.includes('GOS')
            ? formatCurrency(reward.friendsPrize.amount as number, 'GOS')
            : formatCurrency(reward?.friendsPrize?.amount as number, wallets?.[0].currency)}
        </div>
        <div className={cn(_styles.prize, 'lastColumn')}>
          {reward.referrerPrize?.currency?.includes('GOS')
            ? formatCurrency(+reward.referrerPrize.amount, 'GOS')
            : formatCurrency(+(reward?.referrerPrize?.amount as string), wallets?.[0].currency)}
        </div>
      </>
    );
  };

  const getRewardsConfigList = (): void => {
    getRewardsConfigs().then((res: RewardConfigsRes) => {
      if (res.success) {
        setRewards(
          res.result.prizeDistribution.sort((a: PrizeDistribution, b: PrizeDistribution) =>
            a.level > b.level ? 1 : -1
          )
        );
      }
    });
  };

  useEffect(() => {
    if (referralLinks) {
      getRewardsConfigList();
    }
  }, [referralLinks]);

  if (!rewards.length) {
    return null;
  }

  return (
    <div className={_styles.prize}>
      <Typography className={_styles.title} variant={'h6'}>
        {t('refer_a_friend.prizeDistribution')}
      </Typography>
      <div className={_styles.table}>
        <div className={_styles.header}>
          {PRIZE_DISTRIBUTION_TABLE_CAPTIONS.map((caption: string) => (
            <span className={_styles.header_column} key={caption}>
              {t(`refer_a_friend.${caption}`)}
            </span>
          ))}
        </div>
        {rewards?.map((reward: PrizeDistribution) => (
          <div className={_styles.row} key={reward.level}>
            <span className={cn(_styles.row_col, _styles.level)}>
              <SvgIcon src={'/images/refer_a_friend/prize.svg'} />
              {t('refer_a_friend.lvl')} {reward.level}
            </span>
            <span className={cn(_styles.row_col, _styles.pp)}>{reward.trigger.points}</span>
            {setPrizes(reward)}
          </div>
        ))}
      </div>
    </div>
  );
};
export default PrizeDistribution;
