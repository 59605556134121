import { Skeleton } from 'antd';
import cn from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { icons } from 'src/configs/icons';
import { EnumRoutePaths } from 'src/configs/routes';
import { scrollToTop, setBodyOverflow, sliceIntoChunks } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { MediaManager } from 'src/manager/MediaManager';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setGameBoxWidgetOpen, setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { setSelectedGame, setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import { NavigationItem } from 'src/types/res-dto/categories-res';
import { RootState } from 'src/types/store-types';
import { SvgIcon } from '../../common/SvgIcon';
import './styles.scss';

type Props = {
  settings: {
    view: { type: string; placement: string };
    priority: number;
    styles: { [key: string]: any };
    needLogin: false;
    splitCount: number;
    src: string;
    navigationCategories: [];
  };
};

const SideBarNavigation: FC<Props> = ({ settings }) => {
  const { navigationCategories, splitCount } = settings;

  const { t }: Translation = useTranslation();
  const navigate = useNavigate();

  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _selectedGame = useConnectedAction(setSelectedGame);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _setGameBoxWidgetOpen = useConnectedAction(setGameBoxWidgetOpen);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);

  const { sideBarMenuIsOpen, selectedCategory } = useSelector((state: RootState) => state.app);
  const { selectedTag } = useSelector((state: RootState) => state.games);
  const { categoriesData, navigation } = useSelector((state: RootState) => state.configs);

  const [selectMenuItem, setSelectedMenuItem] = useState<string>('');

  const selectTag = (tag: string): void => {
    navigate(`/category/${selectMenuItem}`);

    _setSelectedTag(tag);
    _setSelectedCategory(selectMenuItem);
  };

  const selectGame = (game: any): void => {
    const _game = navigation?.find((f) => f.name === game);
    _selectedGame({ id: _game?.gameId });
  };

  const selectReferAFriend = (): void => {
    const _user = localStorage.getItem('user');

    if (_user) {
      _openAccountModal({ section: 'referAFriend', subSection: 'referAFriend', from: 'menu' });
    } else {
      setBodyOverflow('unset');
      _openAuthModal('login');
    }
  };

  const selectPromotion = (): void => {
    navigate(EnumRoutePaths.PROMOTIONS);
  };

  const selectCategory = (menuItem: string, _navItemData: NavigationItem): void => {
    navigate(`/category/${menuItem}`);
    _setSelectedTag('');
    _setSelectedProvider([]);
    _setSelectedCategory(menuItem);
  };

  const transformCategoryName = (menuItem: string): string => {
    let category;

    if (menuItem === 'betting') {
      category = 'sport';
    } else if (menuItem === 'tournament') {
      category = 'casinoTournament';
    } else {
      category = menuItem;
    }

    return category;
  };

  const checkSubType = useCallback(
    (_navItemData: NavigationItem, menuItem: string): void => {
      _setSelectedCategory(menuItem);
      selectCategory(menuItem, _navItemData);
    },
    [sideBarMenuIsOpen, categoriesData]
  );

  const actionByNavigationType = (type: string, menuItem: string, _navItemData: NavigationItem): void => {
    const category = transformCategoryName(menuItem);

    switch (type) {
      case 'category':
        if (
          categoriesData[menuItem]?.tags?.length < 2 ||
          ['silvaSeries'].includes(menuItem) ||
          (categoriesData[menuItem]?.tags?.length > 2 && !hasChildren(menuItem))
        ) {
          selectCategory(menuItem, _navItemData);
        } else {
          checkSubType(_navItemData, menuItem);
        }

        setSelectedMenuItem(category === selectMenuItem ? '' : category);
        break;
      case 'sport':
        selectCategory(menuItem, _navItemData);
        break;
      case 'game':
        selectGame(menuItem);
        break;
      case 'raceIframe':
      case 'tombala':
        selectCategory(menuItem, _navItemData);
        setSelectedMenuItem(category === selectMenuItem ? '' : category);
        break;
      case 'refer':
        selectReferAFriend();
        break;
      case 'promo':
        selectPromotion();
        setSelectedMenuItem(category === selectMenuItem ? '' : category);
        _setSelectedCategory(menuItem);
        break;
      case 'gamebox':
        _setGameBoxWidgetOpen();
      default:
        setSelectedMenuItem(category === selectMenuItem ? '' : category);
    }
  };

  const changeSelectedMenuItem = (menuItem: string, type = ''): void => {
    scrollToTop();

    const _user = localStorage.getItem('user');

    if (!hasChildren(menuItem) && menuItem !== 'language') {
      const _navItemData = navigation.find((f) => f.name === menuItem) as NavigationItem;

      if (_navItemData?.needLogIn && !_user) {
        setBodyOverflow('unset');
        _openAuthModal('login');
      } else {
        actionByNavigationType(type, menuItem, _navItemData);
      }
    } else {
      setSelectedMenuItem(menuItem === selectMenuItem ? '' : menuItem);
    }
  };

  const changeSelectedMenuIcon = (menuItem: string, type = ''): void => {
    scrollToTop();

    const _navItemData = navigation.find((f) => f.name === menuItem) as NavigationItem;
    const _user = localStorage.getItem('user');

    if (_navItemData?.needLogIn && !_user) {
      setBodyOverflow('unset');
      _openAuthModal('login');
    } else {
      actionByNavigationType(type, menuItem, _navItemData);
    }
  };

  const hasChildren = (category: string): boolean => {
    return Boolean(
      categoriesData[category]?.tags?.length > 2 && navigation?.find((f) => f.name === category)?.hasHomeTag
    );
  };

  const menuIcon = useCallback((category: any) => {
    if (category?.imageSource?.includes('.svg')) {
      return (
        <SvgIcon
          src={MediaManager.getSrcFromMediaAssets(category.name + '.svg', '/categoryIcons/')}
          className={'category_icon'}
        />
      );
    }

    return (
      <img src={MediaManager.getSrcFromMediaAssets(category.imageSource, '/categoryIcons/')} alt={category.name} />
    );
  }, []);

  const renderCategory = useCallback(
    (category: any) => {
      if (sideBarMenuIsOpen) {
        return (
          <React.Fragment key={category.name}>
            <Button
              className={cn('sidebar_section__item secondary-btn', {
                ['selected']: category.name === selectedCategory,
              })}
              onClick={() => changeSelectedMenuItem(category.name, category.type)}
            >
              <div className="sidebar_section__item__container">
                {menuIcon(category)}
                <span className="sidebar_section__item__text">
                  {t(`category_${category.name}${category.type === 'game' ? 'game' : ''}`)}
                </span>
              </div>
              {hasChildren(category.name) && (
                <SvgIcon
                  src={icons.arrowDown}
                  className={cn('arrow', { ['rotateArrow']: selectMenuItem === category.name })}
                />
              )}
            </Button>
            {selectMenuItem === category.name && hasChildren(category.name) && (
              <ul className={'panel'}>
                {categoriesData[category.name]?.tags?.map((tag: any, j: number) => (
                  <li
                    key={j + tag.tagName}
                    className={selectedTag === tag.tagName ? 'selected' : ''}
                    onClick={() => selectTag(tag.tagName)}
                  >
                    {t(tag.tagName)}
                  </li>
                ))}
              </ul>
            )}
          </React.Fragment>
        );
      } else {
        return (
          <Button
            key={category.name}
            className={cn('sidebar_section__item secondary-btn', {
              ['selected']: category.name === selectedCategory,
            })}
            onClick={() => changeSelectedMenuIcon(category.name, category.type)}
          >
            <div className="sidebar_section__item__container">{menuIcon(category)}</div>
          </Button>
        );
      }
    },
    [selectMenuItem, categoriesData, selectedTag, sideBarMenuIsOpen]
  );

  const sideBarItemsArray = useMemo(() => {
    return sliceIntoChunks(navigationCategories, splitCount);
  }, [navigationCategories]);

  const renderItems = (navItems: any): JSX.Element => {
    return (
      <div className="sidebar-section">
        {navItems.filter((f: any) => f.showInNavigation).map((navItem: NavigationItem) => renderCategory(navItem))}
      </div>
    );
  };

  useEffect(() => {
    setSelectedMenuItem(selectedCategory);
  }, [selectedCategory]);

  return (
    <>
      {sideBarItemsArray.length > 0 ? (
        <div className="sidebar__section">
          {sideBarItemsArray?.map((navItem: NavigationItem) => renderItems(navItem))}
        </div>
      ) : (
        <Skeleton.Button active size="large" className="navigation_wrapper__skeleton" />
      )}
    </>
  );
};
export default SideBarNavigation;
