import { FC } from 'react';
import Alert from 'src/components/common/Alert';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';

type Props = {
  transactionNotification: any;
  t: (key: string) => string;
  closeNotification: () => void;
};
const QRPopup: FC<Props> = ({ transactionNotification, t, closeNotification }) => {
  const copyQRCode = (): void => {
    navigator.clipboard.writeText(transactionNotification.message.QRDecoded);
  };

  return (
    <Alert className="notif success_notification_class qr_code_container">
      <SvgIcon src="/images/icons/svg/circle-exclamation-solid.svg" className="notification" color="green" />
      <div className="qr_code_content">
        <p>{transactionNotification.message.message}</p>
        <img src={transactionNotification.message.QRCodeImgUrl} alt="QRCodeImgUrl" />
        <p>{transactionNotification.message.QRDecoded}</p>
      </div>
      <div className="btn-wrapper">
        <Button className="success-filled-btn" onClick={copyQRCode}>
          {t('copyCode')}
        </Button>
        <Button className="success-filled-btn" onClick={closeNotification}>
          {t('done')}
        </Button>
      </div>
    </Alert>
  );
};
export default QRPopup;
