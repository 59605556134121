import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';

type IProps = {
  name: string;
  value: string;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required?: boolean;
};
const City_Input: FC<IProps> = (props) => {
  // Hooks
  const { t }: Translation = useTranslation();

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const resetSignUpError = useResetSignUpError();

  // Selectors
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  // YUP Validation
  const validationSchema = Yup.object().shape({
    city: Yup.string()
      .required(t('requiredField'))
      .matches(new RegExp(props.regexp), t('fieldLength', { count: '2-24' })),
  });

  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ city: value }, { abortEarly: false });
      resetSignUpError('city');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, city: error.errors[0] });
      }
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (signUpErrors.city) {
      resetSignUpError('city');
    }
    props.onChange(e);
  };

  const handleBlur = (): void => {
    handleValidate(props.value);
  };

  return <Input error={signUpErrors.city} {...props} onChange={handleOnChange} onBlur={handleBlur} />;
};
export default City_Input;
