import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { setPageConfigs } from 'src/store/configs/actions';
import { setForceLogout, setLogout } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import { STATIC_IFRAME_URL } from 'src/utils/constants';
import DefaultFrame from './components/DefaultFrame';
import FunMissionIframe from './components/FunMissionIframe';
import PokerIframe from './components/PokerIframe';
import RaceIframe from './components/RaceIframe';
import SportFrame from './components/Sport/SportFrame';
import StaticIframe from './components/StaticIframe';
import TombalaFrame from './components/TombalaFrame';
import { IframeUrl } from './iframeUrls';

const Iframe: FC<IframeType> = ({ type }) => {
  const { locale } = useI18n();

  const _setLogoutReq = useConnectedAction(setLogout);
  const _setPageConfigs = useConnectedAction(setPageConfigs.req);
  const _setForceLogout = useConnectedAction(setForceLogout);

  const { user } = useSelector((state: RootState) => state.user);
  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { categoriesData, navigation } = useSelector((state: RootState) => state.configs);

  const tombalaId = useMemo(() => {
    return navigation?.find((f) => f.name === 'tombala')?.gameId;
  }, [navigation]);

  const [frameData, setFrameData] = useState<any>({
    urls: { loggedIn: '', loggedOut: '' },
    classes: '',
    id: '',
  });

  const onFail = (): void => {
    _setLogoutReq();
  };

  const getFrameData = useCallback(
    async (type: string): Promise<any> => {
      let frame;

      switch (type) {
        case 'tombala':
          frame = IframeUrl.getGameFrameData(
            +(process.env.REACT_APP_SKIN_ID as string),
            user,
            locale,
            { id: tombalaId },
            type,
            'frame',
            onFail
          );
          break;
        case 'sport':
        case 'liveSport':
        case 'betting':
          frame = await IframeUrl.getSportFrameData(
            user,
            +(process.env.REACT_APP_SKIN_ID as string),
            locale,
            categoriesData?.betting?.tags[0]?.tagGames[0],
            'sport liveSport betting'
          );
          break;
        case 'staticIframe':
        case 'static':
          frame = {
            urls: { loggedIn: STATIC_IFRAME_URL[selectedCategory], loggedOut: STATIC_IFRAME_URL[selectedCategory] },
            classes: 'staticIframe',
            id: 'staticIframe',
          };
          break;
        case 'raceIframe':
          frame = await IframeUrl.getRaceFrameData(
            +(process.env.REACT_APP_SKIN_ID as string),
            user,
            locale,
            process.env.REACT_APP_RACE_MODULE
          );
          break;
        case 'blogIframe':
          frame = await IframeUrl.getBlogFrameData(locale, process.env.REACT_APP_BLOG_URL);
          break;
        case 'fun-mission':
          frame = await IframeUrl.getFunMissionData(
            +(process.env.REACT_APP_SKIN_ID as string),
            user,
            locale,
            process.env.REACT_APP_FUN_BONUS_MODULE_URL
          );
          localStorage.removeItem('missionId');
          break;
        case 'pokerIframe':
          frame = await IframeUrl.getPokerFrameData(
            +(process.env.REACT_APP_SKIN_ID as string),
            user,
            locale,
            process.env.REACT_APP_POKER_URL
          );
          break;
        case 'casinoTournament':
          frame = await IframeUrl.getTounamentFrameData(
            +(process.env.REACT_APP_SKIN_ID as string),
            user,
            locale,
            process.env.REACT_APP_WEBSITENAME as string
          );
          break;
        default:
          frame = null;
      }

      return frame;
    },
    [user, categoriesData, locale, selectedCategory]
  );

  const frame = useMemo((): JSX.Element | any => {
    if (frameData) {
      if (['sport', 'betting', 'liveSport'].includes(selectedCategory) && frameData?.id === 'sport') {
        return (
          <SportFrame
            iframeSrc={frameData.urls}
            iframeId={frameData.id}
            iframeClasses={frameData.classes}
            user={user}
            selectedCategory={selectedCategory}
          />
        );
        // TO DO
        // make this check dinamic, after replace route from FE and change navigation response from BE
      } else if (['staticIframe', 'blogIframe'].includes(frameData.id)) {
        return (
          <StaticIframe
            iframeSrc={user ? frameData.urls?.loggedIn : frameData.urls?.loggedOut}
            iframeId={frameData.id}
            iframeClasses={frameData.classes}
          />
        );
      } else if (selectedCategory === 'tombala') {
        return <TombalaFrame iframeSrc={frameData.urls} iframeId={frameData.id} iframeClasses={frameData.classes} />;
      } else if (selectedCategory === 'race' || selectedCategory === 'raceIframe') {
        return (
          <RaceIframe
            iframeSrc={frameData.urls}
            iframeId={frameData.id}
            user={user}
            iframeClasses={frameData.classes}
            selectedCategory={selectedCategory}
          />
        );
      } else if (selectedCategory === 'poker') {
        return (
          <PokerIframe
            iframeSrc={frameData.urls}
            iframeId={frameData.id}
            user={user}
            iframeClasses={frameData.classes}
            selectedCategory={selectedCategory}
          />
        );
      } else if (selectedCategory === 'fun-mission') {
        return (
          <FunMissionIframe
            iframeSrc={frameData.urls}
            iframeId={frameData.id}
            user={user}
            iframeClasses={frameData.classes}
            selectedCategory={selectedCategory}
          />
        );
      } else {
        return (
          <DefaultFrame
            iframeSrc={frameData.urls}
            iframeId={frameData.id}
            iframeClasses={frameData.classes}
            user={user}
            selectedCategory={selectedCategory}
          />
        );
      }
    }

    return <></>;
  }, [frameData, selectedCategory]);

  const getFrameDataReq = async (type: string): Promise<void> => {
    try {
      const res = await getFrameData(type);

      setFrameData(res);
    } catch (e: any) {
      console.log(e);

      if (e.response?.status === 406) {
        _setLogoutReq();
        _setPageConfigs();
        _setForceLogout(e?.response.data.err.lastLoginIp);
      }
    }
  };

  useEffect(() => {
    getFrameDataReq(type);
  }, [type, categoriesData, user]);

  return frame;
};
export default Iframe;
