import { FC, useLayoutEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';

const TawkChat: FC = () => {
  useLayoutEffect(() => {
    if ((window as any)?.Tawk_API && Object.keys((window as any)?.Tawk_API).length) {
      if (isMobileOnly) {
        (window as any)?.Tawk_API?.hideWidget();
      } else {
        (window as any)?.Tawk_API?.showWidget();
      }
    }
    return () => {
      if ((window as any)?.Tawk_API && Object.keys((window as any)?.Tawk_API).length) {
        (window as any)?.Tawk_API?.showWidget();
      }
    };
  }, []);

  return <></>;
};
export default TawkChat;
