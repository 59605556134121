import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import io, { Socket } from 'socket.io-client';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { getMessagesSuccess } from 'src/store/messages/actions';
import { setBalance } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import { useConnectedAction } from './use-connected-action';

type Message = {
  status: string;
  data?: any;
  messageKey?: string;
};

const useMessagesSocket = (): ((type: string, params: any) => void) => {
  const _successMessages = useConnectedAction(getMessagesSuccess);
  const _requestBalance = useConnectedAction(setBalance.req);
  const _openDialog = useConnectedAction(openDialog);
  const { user } = useSelector((state: RootState) => state.user);
  const socketRef = useRef<Socket | null>(null);
  const token = user?.token;
  const userId = user?.id;
  const username = user?.username;

  const handleNewMessage = useCallback(
    (message: Message) => {
      if (!message) return;
      console.log('New socket message received:', message);
      message.status = 'unread';
      if (message.messageKey === 'spinAndGoStarted') {
        _openDialog({
          dialogType: EnumDialogsKeys.SPIN_AND_GO_STARTED,
          dialogProps: { data: message.data },
        });
      }
      _successMessages([message] || []);
    },
    [_openDialog]
  );

  const handleForceLogout = useCallback(() => {
    _requestBalance();
  }, []);

  useEffect(() => {
    if (!process.env.REACT_APP_MESSAGES_URL || !userId || !username || !token) return;
    if (!socketRef.current) {
      socketRef.current = io(process.env.REACT_APP_MESSAGES_URL, {
        query: { username, id: userId, token },
      });
      console.log('Socket connection established:', process.env.REACT_APP_MESSAGES_URL);
    }
    socketRef.current.on('newMessage', handleNewMessage);
    socketRef.current.on('forceLogout', handleForceLogout);
    socketRef.current.on('disconnect', () => {
      console.error('Socket connection disconnected.');
    });

    return () => {
      console.log('Socket connection closed.');
      if (socketRef.current) socketRef.current.disconnect();
      socketRef.current = null;
    };
  }, [process.env.REACT_APP_MESSAGES_URL, userId, username, token, handleNewMessage]);

  useEffect(() => {
    if (process.env.REACT_APP_SKIN_ID && socketRef.current) {
      socketRef.current.emit('getMessages', process.env.REACT_APP_SKIN_ID, (err: any, params: any) => {
        if (err) {
          console.error('Failed to get messages from server.');
        }
        _successMessages(params.payload.messages[userId] || []);
      });
    }
  }, [process.env.REACT_APP_SKIN_ID, socketRef, userId]);

  return useCallback((type: string, params: any) => {
    if (socketRef.current) {
      socketRef.current.emit(type, params);
    }
  }, []);
};

export default useMessagesSocket;
