import cn from 'classnames';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { LeftArrow, RightArrow } from '../Slider/components/Arrows';
import './styles.scss';
import Tag from './Tag';

type Props = {
  showEnd: boolean;
  showStart: boolean;
  onScroll: (e: any) => void;
  tagsContainer: any;
  freeSpinGames: { [key: string]: any };
  selectedCategory: string;
  onGameTagChange: (ev: string) => void;
  selectedTag: string;
  gameTags: any;
  scrollLeft: () => void;
  scrollRight: () => void;
  isScrollBtnVisible: boolean;
};
const SimpleRow: FC<Props> = ({
  showEnd,
  showStart,
  onScroll,
  tagsContainer,
  onGameTagChange,
  selectedTag,
  gameTags,
  scrollLeft,
  scrollRight,
  isScrollBtnVisible,
}) => {
  if (gameTags && gameTags?.allTagNames?.length > 1) {
    return (
      <div className={cn('simple_row_container', { ['scrollable']: isScrollBtnVisible })}>
        {isScrollBtnVisible && !isMobileOnly && (
          <LeftArrow disabled={showEnd} placement="center" type="squaredDark" slide={scrollLeft} />
        )}
        <div onScroll={(e) => onScroll(e.target)} className="wrapper" id="game-tags-wrapper" ref={tagsContainer}>
          {gameTags?.allTagNames?.map((tag: string, i: number) => (
            <Tag key={tag + i.toString()} tag={tag} onGameTagChange={onGameTagChange} selectedTag={selectedTag} />
          ))}
        </div>
        {isScrollBtnVisible && !isMobileOnly && (
          <RightArrow disabled={showStart} placement="center" type="squaredDark" slide={scrollRight} />
        )}
      </div>
    );
  }

  return <></>;
};
export default SimpleRow;
