import { FC } from 'react';
import '../styles.scss';

const SportLoader: FC = () => {
  return (
    <div className="loading_spinner">
      <div className="loader loader-1">
        <div className="loader-outter"></div>
        <div className="loader-inner"></div>
      </div>
    </div>
  );
};

export default SportLoader;
