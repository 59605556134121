import cn from 'classnames';
import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setGameBoxWidgetOpen, setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type IPromotionProps = {
  needLogin: false;
  type: string;
  name: string;
  imageSource: string;
  text?: string;
  itemSize?: string; // large | normal | small
};

type Props = {
  settings: IPromotionProps[];
};

const SideBarPromotion: FC<Props> = ({ settings }) => {
  const navigate = useNavigate();

  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _setGameBoxWidgetOpen = useConnectedAction(setGameBoxWidgetOpen);

  const { sideBarMenuIsOpen, isGameBoxOpen, selectedCategory } = useSelector((state: RootState) => state.app);

  const iframe = document?.getElementById('gamebox-widget') as HTMLIFrameElement;

  const selectCategory = (menuItem: string): void => {
    navigate(`/category/${menuItem}`);
    _setSelectedCategory(menuItem);
  };

  const changeSelectedMenuItem = (menuItem: any): void => {
    const _user = localStorage.getItem('user');

    if (menuItem?.needLogIn && !_user) {
      _openAuthModal('login');
    } else {
      if (menuItem.name === 'smartico') {
        (window as any)._smartico?.dp('dp:gf');
      } else if (menuItem.name === 'gamebox') {
        _setGameBoxWidgetOpen();
      } else {
        selectCategory(menuItem.name);
      }
    }
  };

  useEffect(() => {
    iframe?.contentWindow?.postMessage(
      {
        type: 'CHANGE_WIDGET_VIEW',
        view: isGameBoxOpen ? 'widget' : '',
      },
      '*'
    );
  }, [isGameBoxOpen, selectedCategory]);

  const renderPromotionItems = useCallback(
    (item: IPromotionProps): JSX.Element => {
      let _imageName = item.name;

      !sideBarMenuIsOpen && (_imageName += '-closed');
      selectedCategory === item.name && (_imageName += '-selected');

      return (
        <Button
          className={cn('promotion-item', item?.itemSize)}
          key={item.name}
          type="button"
          onClick={() => changeSelectedMenuItem(item)}
        >
          <img className="promotion-item__image" src={`/images/promotion/${_imageName}.svg`} alt="Promo" />
        </Button>
      );
    },
    [selectedCategory, sideBarMenuIsOpen]
  );

  return (
    <div className="sidebar_promotion_section">
      {settings?.map((promo: IPromotionProps): any => renderPromotionItems(promo))}
    </div>
  );
};

export default SideBarPromotion;
