import React, { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { LeftArrow, RightArrow } from '../Slider/components/Arrows';
import './styles.scss';
import Tag from './Tag';

type Props = {
  showEnd: boolean;
  showStart: boolean;
  isScrollBtnVisible: boolean;
  onScroll: (e: any) => void;
  tagsContainer: any;
  scrollLeft: () => void;
  onGameTagChange: (ev: string) => void;
  selectedTag: string;
  scrollRight: () => void;
  gameTags: any;
};
const BorderedRow: FC<Props> = ({
  showEnd,
  showStart,
  isScrollBtnVisible,
  onScroll,
  tagsContainer,
  scrollLeft,
  onGameTagChange,
  selectedTag,
  scrollRight,
  gameTags,
}) => {
  const { t }: Translation = useTranslation();

  return (
    <div className="bordered_row">
      <div className="header">
        {!isMobileOnly && <p className="title">{t('categories')}</p>}
        {isScrollBtnVisible && (
          <div className="arrows">
            <LeftArrow disabled={showEnd} placement="center" type="squaredDark" slide={scrollLeft} />
            <RightArrow disabled={showStart} placement="center" type="squaredDark" slide={scrollRight} />
          </div>
        )}
      </div>
      {gameTags && gameTags?.allTagNames.length > 1 && (
        <div className="gameTags_container">
          <div
            onScroll={(e) => onScroll(e.target)}
            className="game_tags_wrapper"
            id="game-tags-wrapper"
            ref={tagsContainer}
          >
            {gameTags?.allTagNames?.map((tag: string, i: number) => (
              <Tag key={tag + i.toString()} tag={tag} onGameTagChange={onGameTagChange} selectedTag={selectedTag} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default BorderedRow;
