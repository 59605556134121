import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PaymentView from 'src/constructors/PaymentView';
import { groupPayments } from 'src/helpers/utils';
import { paymentMethodsRequest } from 'src/services/userApi';
import { RootState } from 'src/types/store-types';

const Payment: FC = () => {
  const { accountModal } = useSelector((state: RootState) => state);

  const [paymentMethodsData, setPaymentMethodsData] = useState<PaymentMethod[] | null>([]);
  const [selectedPaymentMethodsData, setSelectedPaymentMethodsData] = useState<PaymentMethod[] | null>([]);
  const [groupedPayments, setGroupedPayments] = useState<{
    deposit: PaymentMethod[];
    withdrawel: PaymentMethod[];
  } | null>(null);

  const getPaymentMethods = (): void => {
    paymentMethodsRequest()
      .then((res: any) => {
        if (res?.success) {
          const _result = res.result.paymentMethods;

          const _deposit = _result.filter((method: any) => method.action === 'deposit');
          const _withdarawel = _result.filter((method: any) => method.action === 'withdraw');

          setGroupedPayments({ deposit: groupPayments(_deposit), withdrawel: groupPayments(_withdarawel) });
          setPaymentMethodsData(_result);
        }
      })
      .catch((e) => {
        console.log(e, 'payment error');
        setPaymentMethodsData(null);
      });
  };

  useEffect(() => {
    getPaymentMethods();
  }, [accountModal.section]);

  useEffect(() => {
    if (paymentMethodsData && paymentMethodsData?.length > 0) {
      setSelectedPaymentMethodsData(
        (paymentMethodsData as PaymentMethod[]).filter((method) => accountModal.section.includes(method.action))
      );
    }

    if (paymentMethodsData === null) {
      setSelectedPaymentMethodsData(null);
    }
  }, [accountModal.section, paymentMethodsData]);

  return (
    <PaymentView
      groupedPayments={groupedPayments?.[accountModal.section]}
      paymentMethodsData={selectedPaymentMethodsData}
      method={accountModal.section}
      view={paymentMethodsData}
    />
  );
};

export default Payment;
