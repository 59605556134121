import cn from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { EnumRoutePaths } from 'src/configs/routes';
import { envBoolean, formatCurrency, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useOutsideClick } from 'src/hooks/use_outside_click';
import { MediaManager } from 'src/manager/MediaManager';
import { getDepositConfigs } from 'src/services/configsApi';
import { getStaticPagesFromCms } from 'src/services/dataApi';
import PersisterService from 'src/services/PersisterService';
import { closeAccountModal } from 'src/store/account-modal/actions';
import {
  setCloseUserSettings,
  setGameBoxWidgetClose,
  setSelectedCategory,
  setShowUserSettings,
  setToggleBonusWallet,
  setToggleVipPoits,
  toggleBalanceVisibility,
} from 'src/store/app/actions';
import { setPageConfigs } from 'src/store/configs/actions';
import { setSelectedTag } from 'src/store/games/actions';
import { toggleMobileMenu } from 'src/store/mobile/actions';
import { setBalance, setLogout } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import DesktopSections from './DesktopSections';
import MobileSections from './MobileSections';
import './styles.scss';

type Props = {
  settings: {
    icon: string;
    needLogin: false;
    priority: number;
    styles: Styles;
    classes: string;
  };
};
const UserSettings: FC<Props> = ({ settings }) => {
  const { classes, styles } = settings;

  const { locale } = useI18n();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t }: Translation = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  const _setGameBoxWidgetClose = useConnectedAction(setGameBoxWidgetClose);
  const _logout = useConnectedAction(setLogout);
  const _setShowUserSettings = useConnectedAction(setShowUserSettings);
  const _setCloseUserSettings = useConnectedAction(setCloseUserSettings);
  const _closeModal = useConnectedAction(closeAccountModal);
  const _toggleMobileMenu = useConnectedAction(toggleMobileMenu);
  const _toggleBalanceVisibility = useConnectedAction(toggleBalanceVisibility);
  const _setToggleBonusWallet = useConnectedAction(setToggleBonusWallet);
  const _setPageConfigs = useConnectedAction(setPageConfigs.req);
  const _setToggleVipPoints = useConnectedAction(setToggleVipPoits);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _requestBalance = useConnectedAction(setBalance.req);
  const _setSelectedTag = useConnectedAction(setSelectedTag);

  const { user, wallets } = useSelector((state: RootState) => state.user);
  const { showUserSetting, showMainBalance, showVipPoints, isGameBoxOpen, showBonusWallet } = useSelector(
    (state: RootState) => state.app
  );
  const { section } = useSelector((state: RootState) => state.accountModal);
  const { showMenu } = useSelector((state: RootState) => state.mobile);
  const { navigation, generalConfigs } = useSelector((state: RootState) => state.configs);

  const [sections, setSections] = useState<Section[]>([]);
  const [selectedSetting, setSelectedSetting] = useState<string>('');
  const [idIsCopied, setIdIsCopied] = useState<boolean>(false);
  const [balanceIsLoading, setBalanceIsLoading] = useState<boolean>(false);

  const onRefresh = (): void => {
    setBalanceIsLoading(true);

    setTimeout(() => {
      setBalanceIsLoading(false);
    }, 200);

    _requestBalance();
  };

  const toggleUserSettings = (): void => {
    _setShowUserSettings();

    if (isMobileOnly) {
      _closeModal();
      showMenu && _toggleMobileMenu();
    }

    setBodyOverflow(showUserSetting ? 'set' : 'unset');
    isGameBoxOpen && _setGameBoxWidgetClose();
  };

  const outSideClick = (): void => {
    setBodyOverflow('set');
    _setCloseUserSettings();
  };

  const logout = (): void => {
    toggleUserSettings();

    if (pathname.includes('category')) {
      const _route = pathname.split('/category/')[1];
      const _selectedRoute = navigation.find((n) => n.name === _route);
      const _defaultRoute = navigation.find((n) => n.isDefault);

      if (_selectedRoute?.needLogIn || envBoolean(process.env.REACT_APP_HAS_LOGIN_PAGE as string)) {
        navigate(EnumRoutePaths.DEFAULT);
        _setSelectedCategory(_defaultRoute?.name);
      }
    }

    localStorage.removeItem('selectedTag');

    _setSelectedTag('');
    _logout();
    _setPageConfigs();
  };

  const copyUserId = (): void => {
    navigator.clipboard.writeText(user.id).then(() => {
      setIdIsCopied(true);

      setTimeout(() => {
        setIdIsCopied(false);
      }, 1500);
    });
  };

  const wallet = useMemo(() => {
    const bonusWallet = wallets?.find((f: any) => f.walletType === 'bonus');
    const mainWallet = wallets?.find((f: any) => f.walletType === 'main');
    return {
      money: formatCurrency(mainWallet?.balance, mainWallet?.currency),
      points: formatCurrency(wallets?.find((f: any) => f.currency === 'GOS')?.balance, 'GOS'),
      bonus: formatCurrency(bonusWallet?.balance, bonusWallet?.currency),
      withdrawableBalance:
        mainWallet?.withdrawableBalance || mainWallet?.withdrawableBalance === 0
          ? formatCurrency(mainWallet?.withdrawableBalance, mainWallet?.currency)
          : null,
    };
  }, [wallets]);

  const getUserSettingsConf = async (): Promise<void> => {
    let res = null;

    if (PersisterService.hasPersistValue('depositConfig')) {
      res = PersisterService.getPersistValue('depositConfig');
      !!res.length && setSections(res);
    } else {
      res = await getDepositConfigs();
      const infoAndRules = await getStaticPagesFromCms(locale);

      if (!!res?.result) {
        if (!!infoAndRules?.data?.[0]) {
          const _infoAndRulesSubsections = infoAndRules.data[0].attributes.info_pages.data?.map(
            (m: { attributes: { Title: string; Name: string } }) => ({
              title: m.attributes.Title,
              name: m.attributes.Name,
            })
          );

          const info_and_rules = res.result.find((r: any) => r.name === 'info_and_rules');
          info_and_rules.children = _infoAndRulesSubsections;
        }

        PersisterService.setPersistValue('depositConfig', res.result);
        setSections(res.result);
      }
    }
  };

  const vipPoints = useMemo(() => {
    if (generalConfigs?.showVipPoints) {
      return (
        <div className="user_settings__balance_info" style={{ width: '49%' }}>
          <div className="balance">
            <span className="title">{t('gos_points')}:</span>
            <span className="value">{showVipPoints ? wallet.points : '*******'}</span>
          </div>
          <SvgIcon
            src={`/images/${showVipPoints ? 'opened' : 'closed'}_eye.svg`}
            className="eye"
            onClick={_setToggleVipPoints}
          />
        </div>
      );
    }

    return <></>;
  }, [generalConfigs, showVipPoints, wallet, locale]);

  const bonusWallet = useMemo(() => {
    return (
      <div className="user_settings__balance_info">
        <div className="balance">
          <span className="title">{t('bonus_balance')}:</span>
          <span className="value">{showBonusWallet ? wallet.bonus : '*******'}</span>
        </div>
        <SvgIcon
          src={`/images/${showBonusWallet ? 'opened' : 'closed'}_eye.svg`}
          className="eye"
          onClick={_setToggleBonusWallet}
        />
      </div>
    );
  }, [generalConfigs, showBonusWallet, wallet, locale]);

  const withdrawableBalance = useMemo(() => {
    if (wallet.withdrawableBalance || Number(wallet.withdrawableBalance) === 0) {
      return (
        <div className="user_settings__withdrawableBalance_info">
          <Typography className="label" variant={'h5'}>
            {t('withdrawableBalance')}:
          </Typography>
          <Typography className="label" variant={'h4'}>
            {wallet.withdrawableBalance}
          </Typography>
        </div>
      );
    }
    return null;
  }, [generalConfigs, showBonusWallet, wallet, locale]);

  useEffect(() => {
    getUserSettingsConf();
    _requestBalance();
  }, []);

  useEffect(() => {
    if (section.length === 0) {
      _closeModal();

      isMobile && setSelectedSetting('');
    }
  }, [section]);

  useOutsideClick(ref, outSideClick, ['user_icon'], isMobileOnly ? ['user_icon', 'account-modal'] : null);

  return (
    <div className="user_settings_wrapper" ref={ref}>
      <div className="user_icon">
        <Button
          ariaLabelledby="userIcon"
          id="user_icon"
          style={styles}
          className={classes}
          onClick={toggleUserSettings}
        >
          <div className="user_icons_inner_container">
            <SvgIcon src="/images/user.svg" className="user_header_avatar" />
            <SvgIcon src="/images/icons/svg/filled_arrow_down.svg" className="arrow" />
          </div>
          {isMobileOnly && <p>{showMainBalance ? wallet.money : '****'}</p>}
        </Button>
      </div>
      {showUserSetting && (
        <div className="user_settings secondary-menu">
          {isMobileOnly && (
            <img src={MediaManager.getSrcFromMediaAssets('logo.svg', '/logo/')} alt="Logo" className="logo" />
          )}
          <div className="user_settings_info">
            <div className="user">
              <SvgIcon src="/images/settings/yellowUser.svg" className="user_avatar" />
            </div>
            {isMobileOnly ? (
              <div className="modal_header">
                <div className="user_info">
                  <span className="username">{user.nickname ?? user.username}</span>
                  <div className="userId">
                    <span>
                      {t('id')} {user.id}
                    </span>
                    <SvgIcon src="/images/copy.svg" className="copy" onClick={copyUserId} />
                    <span className="copied">{idIsCopied && t('copied')}</span>
                  </div>
                </div>

                <Button type="button" className={'close_menu_wrapper'} onClick={toggleUserSettings}>
                  <SvgIcon src={icons.close} />
                </Button>
              </div>
            ) : (
              <div className="user_info">
                <p>{user?.nickname ?? user?.username}</p>
                <p>
                  {t('id')} {user?.id}
                </p>
              </div>
            )}
          </div>
          {!!isMobileOnly && (
            <div className="user_balance_wrapper">
              <div
                className="user_settings__balance_info"
                style={{ width: generalConfigs?.showVipPoints ? '49%' : '100%' }}
              >
                <div className="balance">
                  <span className="title">{t('main_balance')}:</span>
                  <p className="value">{showMainBalance ? wallet.money : '*******'}</p>
                </div>
                <div className="icons_wrapper">
                  <SvgIcon
                    src={`/images/${showMainBalance ? 'opened' : 'closed'}_eye.svg`}
                    className="eye"
                    onClick={_toggleBalanceVisibility}
                  />
                  <SvgIcon
                    onClick={onRefresh}
                    src="/images/refresh-bold.svg"
                    className={cn('user_balance_refresh', {
                      ['user_balance_loading']: balanceIsLoading,
                    })}
                  />
                </div>
              </div>
              {vipPoints}
              {withdrawableBalance}
            </div>
          )}
          {isMobileOnly && generalConfigs?.showBonusWallet && (
            <div className="user_balance_wrapper bonus_wallet">{bonusWallet}</div>
          )}
          <span className="user_settings__separator_line" />
          <ul className="user_settings_list">
            {isMobileOnly ? (
              <MobileSections
                selectedSetting={selectedSetting}
                setSelectedSetting={setSelectedSetting}
                sections={sections}
              />
            ) : (
              <DesktopSections toggleUserSettings={toggleUserSettings} sections={sections} />
            )}
            <li className="logout_btn">
              <Button type="button" className="error-text-btn" onClick={logout}>
                <SvgIcon src="/images/settings/logout.svg" />
                {t('button_logOut')}
              </Button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default UserSettings;
