import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeErrorMessage } from 'src/store/dialog/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

const ErrorMessage: FC = () => {
  const { t }: Translation = useTranslation();

  const _closeErrorMessage = useConnectedAction(closeErrorMessage);

  const { showErrorPopup, error, errorParam, requestTrackId } = useSelector((state: RootState) => state.dialog);

  const removeDialog = (): void => {
    _closeErrorMessage();
  };

  useEffect(() => {
    if (showErrorPopup) {
      setBodyOverflow('unset');
    }
  }, [showErrorPopup]);

  if (error && error !== 'undefined') {
    return (
      <PopUpContainer
        isOpen={showErrorPopup}
        handleClose={removeDialog}
        handleOK={removeDialog}
        icon={<SvgIcon src={icons.errorIcon} />}
        type="small"
        hasOverlay
        id="error_message"
        zIndex={160}
      >
        <p className="error_message_title">{t(error, { param: errorParam })}</p>
        {requestTrackId && (
          <span className="error_message_id">
            {t('id')}: {requestTrackId}
          </span>
        )}
      </PopUpContainer>
    );
  }
  return <></>;
};

export default ErrorMessage;
