import { FC, useEffect, useState } from 'react';
import ErrorMessage from './ErrorMessage';
import _styles from './styles.module.scss';

type Props = {
  data: {
    payment: PaymentMethod;
    paymentField: any;
    onchange: (ev: any, field: string) => void;
    t: (arg: string) => string;
    error: { [keys: string]: string } | null;
    setError: (error: any) => void;
    forceUpdate: boolean;
  };
};
const Input: FC<Props> = ({ data }) => {
  const { payment, onchange, paymentField, t, error, setError } = data;
  const { validation, type } = payment.fields[paymentField];

  const [inpValue, setInpValue] = useState<any>(null);

  const handleInputChange = (ev: any): void => {
    const errors = { ...error };
    delete errors[paymentField];

    setError(errors);
    onchange(type === 'number' ? +ev.target.value : ev.target.value, paymentField);
    setInpValue(ev.target.value);
  };

  useEffect(() => {
    const errors = { ...error };
    delete errors[paymentField];

    setError(errors);
    setInpValue('');
    onchange('', paymentField);
  }, [payment.methodId, paymentField, payment.action]);

  return (
    <div className={_styles.input_container}>
      <label>{t(paymentField)}</label>
      <input type={type} name={paymentField} value={inpValue} onChange={handleInputChange} />
      {paymentField === 'amount' && <span className={_styles.payment_currency}>{validation.range.currency}</span>}
      {error && <ErrorMessage error={error} paymentField={paymentField} />}
    </div>
  );
};
export default Input;
