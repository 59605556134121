export enum EnumDialogsKeys {
  RESET_PASSWORD_SUCCESS = 'resetPasswordSuccess',
  CHANGE_PASSWORD_SUCCESS = 'changePasswordSuccess',
  RESUME_TOURNAMENT = 'resumeTournament',
  TOURNAMENT_RESULTS = 'tournamentResults',
  FINAL_TOUR_INFO = 'finalTourInfo',
  SECCESSFULLY_REGED = 'successFullyReged',
  SPIN_AND_GO_STARTED = 'spinAndGoStarted',
  REGISTRATION_FAILURE = 'REGISTRATION_FAILURE',
  FREE_SPINS = 'freespins',
  GAME_LAUNCH_PREVIEW = 'gameLaunchPreview',
  FIRST_DEPOSIT_TO_BONUS_WALLET = 'firstDepositToBonusWallet',
  MAIN_TO_POKER_TRANSACTION = 'mainToPokerTransaction',
}

export interface IDialogManagerState {
  dialogs: Record<string, any>;
  error: string;
  showErrorPopup: boolean;
  requestTrackId: string;
  errorParam: string;
}

export interface IOpenDialogPayload<T extends EnumDialogsKeys> {
  dialogType: T;
  dialogProps?: IDialogTypes[T];
}

export interface ICloseDialogPayload<T extends EnumDialogsKeys> {
  dialogType: T;
  dialogProps?: IDialogTypes[T];
}

interface IDialogTypes {
  [EnumDialogsKeys.RESET_PASSWORD_SUCCESS]: IResetPasswordSuccessDialogProps;
  [EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS]: IResetPasswordSuccessDialogProps;
  [EnumDialogsKeys.RESUME_TOURNAMENT]: IResumeTournamentDialogProps;
  [EnumDialogsKeys.TOURNAMENT_RESULTS]: ITournamenResultsDialogProps;
  [EnumDialogsKeys.FINAL_TOUR_INFO]: IFinalTourInfoDialogProps;
  [EnumDialogsKeys.SECCESSFULLY_REGED]: ISuccessfullyRegedDialogProps;
  [EnumDialogsKeys.SPIN_AND_GO_STARTED]: ISpinAndGoStarted;
  [EnumDialogsKeys.REGISTRATION_FAILURE]: IRegistrationFailureProps;
  [EnumDialogsKeys.FREE_SPINS]: IRegistrationFailureProps;
  [EnumDialogsKeys.GAME_LAUNCH_PREVIEW]: IAnyProps;
  [EnumDialogsKeys.FIRST_DEPOSIT_TO_BONUS_WALLET]: IAnyProps;
  [EnumDialogsKeys.MAIN_TO_POKER_TRANSACTION]: IAnyProps;
}

interface IResetPasswordSuccessDialogProps {
  email?: string;
  dialogCase: string;
}
interface IResumeTournamentDialogProps {
  data: any;
}
interface ITournamenResultsDialogProps {
  data: any;
}
interface IFinalTourInfoDialogProps {
  data: any;
}
interface ISuccessfullyRegedDialogProps {
  data: any;
}
interface ISpinAndGoStarted {
  data: any;
}
interface IAnyProps {
  data: any;
}

interface IRegistrationFailureProps {
  data: any;
}

export const DIALOG_MANAGER_OPEN_DIALOG = 'dialogManager/OPEN_DIALOG';
export const DIALOG_MANAGER_CLOSE_DIALOG = 'dialogManager/CLOSE_DIALOG';

export const DIALOG_MANAGER_OPEN_ERROR_MESSAGE = 'dialogManager/OPEN_ERROR_MESSAGE';
export const DIALOG_MANAGER_CLOSE_ERROR_MESSAGE = 'dialogManager/CLOSE_ERROR_MESSAGE';
