import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { EnumRoutePaths } from 'src/configs/routes';
import { hasChildren, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setGameBoxWidgetOpen, setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { setSelectedGame, setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import { toggleMobileMenu } from 'src/store/mobile/actions';
import { RootState } from 'src/types/store-types';

const useNavigation = (): any => {
  //const navigate = useNavigate();
  const { pathname } = useLocation();

  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _selectedGame = useConnectedAction(setSelectedGame);
  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);
  const _toggleMobileMenu = useConnectedAction(toggleMobileMenu);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setGameBoxWidgetOpen = useConnectedAction(setGameBoxWidgetOpen);

  const { navigation, categoriesData } = useSelector((state: RootState) => state.configs);
  const { showMenu } = useSelector((state: RootState) => state.mobile);
  const { user } = useSelector((state: RootState) => state.user);
  const url = localStorage.getItem('url') as string;

  const selectPromotion = (setSelectedMenuItem?: any): void => {
    window.location.href = `${url}${EnumRoutePaths.PROMOTIONS}`;
    //navigate(EnumRoutePaths.PROMOTIONS);

    if (pathname.includes(EnumRoutePaths.PROMOTIONS)) return;

    if (isMobileOnly && showMenu && setSelectedMenuItem) {
      setBodyOverflow('set');
      setSelectedMenuItem('');
      _toggleMobileMenu();
    }

    _setSelectedCategory('');
  };

  const selectMenuPromo = (promoObj: any, setSelectedMenuItem: any): void => {
    const _user = localStorage.getItem('user');

    if (promoObj?.needLogIn && !_user) {
      window.location.href = url;
      _openAuthModal('login');
    } else {
      if (promoObj.promo === 'gamebox') {
        _setGameBoxWidgetOpen();
      } else if (promoObj.promo === 'super_arena') {
        window.location.href = `${url}/promotions?promo=${promoObj.promo_id}`;
        // navigate(`/promotions?promo=${promoObj.promo_id}`);

        if (isMobileOnly && showMenu && setSelectedMenuItem) {
          setBodyOverflow('set');
          setSelectedMenuItem('');
          _toggleMobileMenu();
        }
      } else {
        selectCategory(promoObj.promo);
      }
    }
  };

  const selectGame = (game: any): void => {
    _selectedGame({ id: game?.gameId });
  };

  const selectExternalLink = (redirectionUrl: string): void => {
    window.open(redirectionUrl, '_blank', 'noreferrer');
  };

  // navigate(`/category/${navItem}`);
  const selectCategory = (navItem: string, navigationItem?: any): void => {
    if (navigationItem?.subType === 'staticPage') {
      window.location.href = `${url}/${navItem}`;
      // navigate(`/${navItem}`);
    } else {
      window.location.href = `${url}/category/${navItem}`;
      // navigate(`/category/${navItem}`);
    }

    _setSelectedTag('');
    _setSelectedProvider(['all_providers']);
    _setSelectedCategory(navItem);
  };

  const selectContent = (navItem: string): void => {
    window.location.href = `${url}/content/${navItem}`;
    // navigate(`/content/${navItem}`);
    _setSelectedCategory(navItem);
  };

  const selectReferAFriend = (setSelectedMenuItem: any): void => {
    if (user) {
      _openAccountModal({ section: 'referAFriend', subSection: 'referAFriend', from: 'menu' });

      if (isMobileOnly && showMenu && setSelectedMenuItem) {
        setBodyOverflow('set');
        setSelectedMenuItem('');
        _toggleMobileMenu();
      }
    } else {
      _openAuthModal('login');
    }
  };

  const checkSubType = (_navItemData: any, menuItem: string): void => {
    if (_navItemData && _navItemData?.subType === 'staticPage') {
      selectCategory(menuItem);
    } else {
      _setSelectedTag(categoriesData[menuItem]?.tags?.[0].tagName);
      _setSelectedCategory(menuItem);
    }
  };

  const callNavigationAction = (
    navigationItem: any,
    type: any,
    setSelectedMenuItem?: any,
    selectedMenuItem?: string
  ): void => {
    setSelectedMenuItem && setSelectedMenuItem(navigationItem.name === selectedMenuItem ? '' : navigationItem.name);
    switch (type) {
      case 'game':
        selectGame(navigationItem);
        break;
      case 'externalLink':
        selectExternalLink(navigationItem.redirectionUrl);
        break;
      case 'content':
        selectContent(navigationItem.name);
        break;
      case 'promo':
        selectPromotion(setSelectedMenuItem);
        break;
      case 'refer':
        selectReferAFriend(setSelectedMenuItem);
        break;
      case 'category':
      case 'sport':
        if (isMobileOnly) {
          if (
            categoriesData[navigationItem.name]?.tags?.length < 2 ||
            ['silvaSeries', 'dinhutech', 'crashGames', 'virtualSport'].includes(navigationItem.name) ||
            (categoriesData[navigationItem.name]?.tags?.length > 2 &&
              !hasChildren(categoriesData, navigationItem.name, navigation))
          ) {
            selectCategory(navigationItem.name);
          } else {
            checkSubType(navigationItem, navigationItem.name);
          }
        } else {
          selectCategory(navigationItem.name);
        }

        break;
      case 'raceIframe':
      case 'tombala':
      case 'casinoTournament':
        selectCategory(navigationItem.name);
        break;
      default:
        selectCategory(navigationItem.name, navigationItem);
    }
  };

  const openGamebox = (): void => {
    _setGameBoxWidgetOpen();
  };

  return {
    callNavigationAction: (arg1: any, arg2: any, arg3: any) => callNavigationAction(arg1, arg2, arg3),
    selectGame: (arg: any) => selectGame(arg),
    selectExternalLink: (arg: string) => selectExternalLink(arg),
    selectContent: (arg: any) => selectContent(arg),
    selectCategory: (arg: any) => selectCategory(arg),
    selectPromotion: (arg: any) => selectPromotion(arg),
    selectMenuPromo: (arg1: any, arg2: any) => selectMenuPromo(arg1, arg2),
    openGamebox: () => openGamebox(),
  };
};

export default useNavigation;
