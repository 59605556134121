import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { setSelectedCategory, setTournamentIsOpen } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { setGameBoxMissions, setTournamentGame, setTournamentModulePageView } from 'src/store/integrations/actions';
import { setBalance } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import '../styles.scss';

const DefaultFrame: FC<FrameTypes> = ({ iframeSrc, iframeId, iframeClasses, user, selectedCategory }) => {
  const { locale } = useI18n();

  const _openDialog = useConnectedAction(openDialog);

  const navigate = useNavigate();

  const _requestGameBoxMission = useConnectedAction(setGameBoxMissions.req);
  const _resetExternalTournamentGame = useConnectedAction(setTournamentGame);
  const _requestBalance = useConnectedAction(setBalance.req);
  const _setTournamentModulePageView = useConnectedAction(setTournamentModulePageView);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setTournamentIsOpen = useConnectedAction(setTournamentIsOpen);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);

  const { selectedTournamentGame, showTournamentPage } = useSelector((state: RootState) => state.integrations);

  const iframe = (document?.getElementById(iframeId) as HTMLIFrameElement)?.contentWindow;

  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [iframeOnLoaded, setIframeOnLoaded] = useState<boolean>(false);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleMessages = (event: any): void => {
    if (event.data.type === 'spinAndGoStarted') {
      _openDialog({
        dialogType: EnumDialogsKeys.SPIN_AND_GO_STARTED,
        dialogProps: { data: { tournamentId: event.data.tournamentId } },
      });
    }
    if (event.data.type === 'navigate/category') {
      _setSelectedCategory('silvaSeries');
      navigate(`/category/silvaSeries`);
    }

    if (event.data.type === 'CHANGE_CATEGORY') {
      _setSelectedCategory(event.data.category);
      navigate(`/category/${event.data.category}`);
    }

    if (event.data.name === 'resumeTournament' && !localStorage.getItem('resumeGameId')) {
      _openDialog({
        dialogType: EnumDialogsKeys.RESUME_TOURNAMENT,
        dialogProps: { data: event.data.tournament },
      });
    }

    if (event.data.name === 'SUCCESSFULL_REGISTRATION') {
      localStorage.setItem('resumeGameId', event.data.id);
    }

    if (event.data.name === 'SUCCESSFULL_UNREGISTRATION') {
      localStorage.removeItem('resumeGameId');
    }

    if (event.data.name === 'openLoginForm' && !user) {
      setBodyOverflow('unset');
      _openAuthModal('login');
    }

    if (event.data.name === 'gameStateChanged') {
      if (!event.data.fullScreen) {
        setTimeout(() => {
          if (user) {
            _requestGameBoxMission();
            _resetExternalTournamentGame(null);
          }
        }, 500);
      }

      setFullScreen(event.data.fullScreen);
    } else if (event.data.name === 'balanceUpdate') {
      _requestBalance();
    }
  };

  const onLoadFn = (): void => {
    setIframeOnLoaded(true);
  };

  useEffect(() => {
    if (iframe) {
      if (selectedCategory !== 'casinoTournament') {
        _setTournamentModulePageView(false);

        iframe.postMessage({ type: 'closeGame' }, '*');

        setTimeout(() => {
          iframe.postMessage({ type: 'closeDialogByHostInitiative', dialogType: 'howToPlay' }, '*');
          iframe.postMessage({ type: 'closeDialogByHostInitiative', dialogType: 'tournamentsHistory' }, '*');
        }, 100);
      }

      if (iframeOnLoaded) {
        if (locale) {
          setTimeout(() => {
            iframe.postMessage({ type: 'languageChange', language: locale }, '*');
          }, 100);
        }

        if (selectedTournamentGame) {
          setTimeout(() => {
            setFullScreen(true);
            iframe.postMessage({ type: 'openGame', game: selectedTournamentGame }, '*');
          }, 100);
        }

        if (showTournamentPage) {
          setTimeout(() => {
            iframe.postMessage({ type: 'openDialogByHostInitiative', dialogType: 'howToPlay' }, '*');
          }, 100);
        }
      }
    }
  }, [user, iframe, selectedTournamentGame, iframeOnLoaded, locale]);

  useEffect(() => {
    if (iframe && user) {
      iframe.postMessage({ type: 'triggerBalance' }, '*');
    }
  }, [user?.wallet, iframe]);

  useEffect(() => {
    window.addEventListener('message', handleMessages);
    return () => window.removeEventListener('message', handleMessages);
  }, []);

  useEffect(() => {
    _setTournamentIsOpen(fullScreen);
  }, [fullScreen]);

  return (
    <iframe
      className={cn(iframeClasses, { ['fullScreen']: fullScreen })}
      id={iframeId}
      src={user ? iframeSrc?.loggedIn : iframeSrc?.loggedOut}
      onLoad={onLoadFn}
      allow="autoplay"
      allowFullScreen
    />
  );
};

export default DefaultFrame;
