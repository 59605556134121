import { GameParamType } from 'src/types/req-dto/game';
import { GameResType } from 'src/types/res-dto/games';
import HttpClient from './http';

const gameUrlRequest = (payload: GameParamType): Promise<GameResType> => {
  const device = payload.device === 'mobile' ? 'mobile' : 'desktop';
  return HttpClient.get(
    { path: `/lobby/${payload.userId ? 'gameUrl' : 'publicGameUrl'}`, baseURL: process.env.REACT_APP_REMOTE as string },
    Boolean(payload.userId),
    { ...payload, device }
  );
};

const getCategoriresData = (device: string, limit = 25): Promise<GameResType> => {
  return HttpClient.get(
    {
      path: `/lobbyV2/getLobbyStructure?skinId=${process.env.REACT_APP_SKIN_ID}&status=ok&device=${device}&limit=${limit}`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    false
  );
};

const gameListRequest = (
  device: string,
  providers: string[],
  offset: number,
  selectedCategory: string,
  selectedTag: string[] | null,
  gamesCount: number | null
): Promise<GameResType> => {
  let searchParams = '';

  if (selectedCategory !== 'none') searchParams += `categories[]=${selectedCategory}&`;
  if (offset) searchParams += `offset=${offset}&`;

  const _ = Array.isArray(providers) ? providers?.filter((f: string) => !['all_providers', ''].includes(f)) ?? [] : [];

  if (_.length > 0) {
    _.map((m: string) => (searchParams += `providerNames[]=${m}&`));
  }

  if (selectedTag && !['allGames', ''].includes(selectedTag[0])) searchParams += `tags[]=${selectedTag}&`;

  return HttpClient.get(
    {
      path: `/lobbyV2/getGamesList?${searchParams}&limit=${gamesCount ?? 0}&skinId=${
        process.env.REACT_APP_SKIN_ID
      }&status=ok&device=${device}`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    false
  );
};

const seacrhByGameNameRequest = (
  device: string,
  gameName: string,
  providers: string[] | null,
  selectedTag: string[] | null,
  selectedCategory: string
): Promise<GameResType> => {
  let searchParams = `namePart=${encodeURIComponent(gameName)}`;

  if (selectedCategory) searchParams += `&categories[]=${selectedCategory}&`;

  const _ = providers?.filter((f: string) => f !== 'all_providers') ?? [];

  if (_.length > 0) {
    _.map((m: string) => (searchParams += `providerNames[]=${m}&`));
  }

  if (selectedTag && !['allGames', 'home', 'free_rounds'].includes(selectedTag[0]))
    searchParams += `&tags[]=${selectedTag}`;

  return HttpClient.get(
    {
      path: `/lobbyV2/searchGames?${searchParams}&skinId=${process.env.REACT_APP_SKIN_ID}&status=ok&device=${device}`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    false
  );
};

const getMessages = (accountId: string): Promise<GameResType> => {
  return HttpClient.get({
    path: `api/message?skinId=${process.env.REACT_APP_SKIN_ID}&accountId=${accountId}`,
    baseURL: process.env.REACT_APP_MESSAGES_URL as string,
  });
};

const getLast5Games = (category: string): Promise<GameResType> => {
  return HttpClient.get(
    {
      path: `/api/getRecentGames?gameType=${category}`,
      baseURL: process.env.REACT_APP_REPORTS as string,
    },
    true
  );
};

export { gameUrlRequest, getCategoriresData, gameListRequest, seacrhByGameNameRequest, getMessages, getLast5Games };
