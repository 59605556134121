import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { CSLoader } from 'src/components/common/CsLoader';
import { Typography } from 'src/components/common/Typography';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setSectionLoading } from 'src/store/app/actions';
import { setCategoriesData } from 'src/store/configs/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

const SectionLoaderPage: FC = () => {
  const { t } = useTranslation();

  const _setCategoriesData = useConnectedAction(setCategoriesData.req);
  const _setSectionLoading = useConnectedAction(setSectionLoading);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const getMainAPIs = (): void => {
    setIsLoading(true);
    setBodyOverflow('unset');

    Promise.all([_setCategoriesData()])
      .then(() => {
        setTimeout(() => {
          _setSectionLoading(false);
          setBodyOverflow('set');
        }, 1500);
      })
      .catch(() => setHasError(true));
  };

  useEffect(() => {
    getMainAPIs();
  }, []);

  return (
    <div
      className={cn('section_loader_container', {
        ['fullScreen']: generalConfigs?.template === 'template_fullScreen',
        ['noFullScreen']: generalConfigs?.template === 'template_noFullScreen',
      })}
    >
      {isLoading && <CSLoader speed="slow" />}
      {hasError && (
        <div className="error_wrapper">
          <Typography className="text_1" variant="subtitle1">
            Oops! {t('smth_went_wrong')}!
          </Typography>
          <Typography className="text_1" variant="subtitle1">
            {t('please_try_again')}!
          </Typography>
          <Button className="primary-filled-btn back_to_home" onClick={getMainAPIs}>
            {t('reload')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SectionLoaderPage;
