import cn from 'classnames';
import { FC } from 'react';
import { MediaManager } from 'src/manager/MediaManager';
import styles from './styles.module.scss';

type Props = {
  width?: string | number;
  height: string | number;
  borderRadius?: string;
  useGif?: boolean;
};

const GameCardSceleton: FC<Props> = ({ borderRadius, width, useGif = false }) => {
  return (
    <div
      className={cn(styles.skeleton, { [styles.gif]: useGif, [styles.animation]: !useGif })}
      style={{
        backgroundImage: `url(${MediaManager.getSrcFromMediaAssets(
          useGif ? 'game_loader.gif' : 'gameLoader.png',
          '/logo/'
        )})`,
        width: width,
        height: '96%',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          borderRadius: borderRadius ?? '0px',
        }}
        className={styles.skeleton__item}
      />
    </div>
  );
};

export default GameCardSceleton;
