import { createReducer } from 'deox';
import produce from 'immer';
import {
  resetForceLogout,
  setBalance,
  setCPF_User,
  setError,
  setForceLogout,
  setLoading,
  setLogout,
  setPassword,
  setPasswordError,
  setPhoneCode,
  setReferralLink,
  signIn,
  toggleActivateBtn,
} from './actions';
import { userState } from './types';

export const userInitialState: userState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null,
  additionalInfo: null,
  loading: false,
  authLoading: false,
  balanceIsLoading: false,
  wallets: localStorage.getItem('wallets') ? JSON.parse(localStorage.getItem('wallets') || '') : null,
  gamePreviewProducts: [],
  activateButton: false,
  errors: {},
  password: '',
  repeatPassword: '',
  phoneCode: '',
  passwordError: '',
  forceLogout: null, // user
  referralLinks: localStorage.getItem('referralLinks') ? JSON.parse(localStorage.getItem('referralLinks') || '') : null,
};

export const userReducer = createReducer(userInitialState, (handle) => [
  handle(setLogout, (state, {}: any) =>
    produce(state, (draft) => {
      draft.user = null;
      localStorage.removeItem('user');
      draft.referralLinks = null;
      localStorage.removeItem('referralLinks');
      sessionStorage.removeItem('POKER_URL');
    })
  ),
  handle(signIn.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.user = payload;
    })
  ),
  handle(signIn.load, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.authLoading = payload;
    })
  ),
  handle(setError, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.errors = payload;
    })
  ),
  handle(setLoading, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.loading = payload;
    })
  ),
  handle(setCPF_User, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.additionalInfo = payload;
    })
  ),
  handle(setBalance.req, (state, {}: any) =>
    produce(state, (draft) => {
      draft.balanceIsLoading = true;
    })
  ),
  handle(setBalance.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.wallets = payload;
      draft.gamePreviewProducts = payload.find((wal: any) => wal?.walletType === 'bonus')?.products || [];
      const storedWallets = localStorage.getItem('wallets');
      if (!!storedWallets) {
        localStorage.setItem('wallets', JSON.stringify(payload));
      }
      draft.balanceIsLoading = false;
    })
  ),
  handle(toggleActivateBtn, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.activateButton = payload;
    })
  ),
  handle(setPassword, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft[payload.key] = payload.value;
    })
  ),
  handle(setPhoneCode, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.phoneCode = payload;
    })
  ),
  handle(setForceLogout, (state, { payload }: any) =>
    produce(state, (draft) => {
      localStorage.removeItem('user');
      draft.forceLogout = { message: payload };
    })
  ),
  handle(resetForceLogout, (state) =>
    produce(state, (draft) => {
      draft.forceLogout = null;
    })
  ),
  handle(setPasswordError, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.passwordError = payload;
    })
  ),
  handle(setReferralLink, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.referralLinks = payload;
      localStorage.setItem('referralLinks', JSON.stringify(payload));
    })
  ),
]);
