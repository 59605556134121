import { FC } from 'react';
import { getRowNumber } from 'src/helpers/utils';
import GamesSlider from '../Slider/GamesSlider';

type Props = {
  gameTags: { allTagData: any; allTagNames: string[] };
  homeTag: any;
};
const HomePage: FC<Props> = ({ gameTags, homeTag }) => {
  return (
    <>
      {gameTags?.allTagData?.tags
        ?.sort(
          (a: { tagName: string; priority: number }, b: { tagName: string; priority: number }) =>
            b?.priority - a?.priority
        )
        ?.map((tagData: any) => {
          return (
            <GamesSlider
              key={tagData.tagName}
              data={tagData.tagGames}
              tagName={tagData.tagTrName || tagData.tagName}
              template={`template_${
                homeTag.homeTagSettings?.[tagData.tagName]?.gameSliderTemplate || getRowNumber(tagData?.viewType)
              }`}
            />
          );
        })}
    </>
  );
};
export default HomePage;
