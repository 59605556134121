import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import '../styles.scss';

type Props = {
  widgetData: any;
  btnStyles?: string;
  redirect: (ev: any, attributes: any) => void;
  cls: string;
};
const HomeWidget: FC<Props> = ({ widgetData, btnStyles, redirect, cls }) => {
  const { t }: Translation = useTranslation();
  const imageSrc = widgetData?.imageSrc;
  if (!imageSrc || imageSrc.includes('undefined')) {
    return <div style={{ color: 'red' }}>Image is broken</div>;
  }
  // const redirection = widgetData?.OnClickRedirection;
  return (
    <div className={cls} style={{ backgroundImage: `url(${imageSrc})` }} onClick={(ev) => redirect(ev, widgetData)}>
      {!!widgetData.buttonActions?.targetParam && (
        <Button className={cn('action-btn', btnStyles)} onClick={(ev) => redirect(ev, widgetData.buttonActions)}>
          {t(`goTo${widgetData.buttonActions?.targetParam}`) ?? `Go To ${widgetData.buttonActions.targetParam}`}
        </Button>
      )}
    </div>
  );
};
export default HomeWidget;
