import { FC, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Tooltip from 'src/components/common/Tooltip';
import _styles from './styles.module.scss';

type Props = {
  title: string;
  value: string | number | null;
};
const AccountDetail: FC<Props> = ({ title, value }) => {
  // TO DO remove this obj after normal skining of pallete
  const stylesObj = useMemo(() => {
    if (isMobileOnly && process.env.REACT_APP_WEBSITENAME === 'Betelite') {
      return {
        backgroundColor: '#1f212c',
      };
    } else {
      return {};
    }
  }, []);
  return (
    <div className={_styles.detail_container} style={stylesObj}>
      <span className={_styles.title}>{title}</span>
      {value ? (
        <Tooltip position="top" text={String(value)} className={_styles.tooltip_wrapper}>
          <span className={_styles.value}>{value}</span>
        </Tooltip>
      ) : (
        <span className={_styles.title}>-</span>
      )}
    </div>
  );
};
export default AccountDetail;
