import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import GameBox from 'src/components/base-components/GameBox';
import GameBoxMessaging from 'src/components/base-components/GameBoxMessaging';
import AccountModal from 'src/components/base-components/Modals/AccountModal';
import Dialogs from 'src/components/base-components/Modals/Dialogs';
import EmailVerificationModal from 'src/components/base-components/Modals/EmailVerificationModal';
import ErrorMessage from 'src/components/base-components/Modals/ErrorMessage';
import ForceLogout from 'src/components/base-components/Modals/ForceLogoutModal';
import InactivityModal from 'src/components/base-components/Modals/InactivityModal';
import Routing from 'src/components/Routes/Routing';
import {
  configsVariablesHandler,
  detectDevice,
  envBoolean,
  getFreeSpinMessages,
  getFromQueryParams,
  isTokenExpirate,
  isTokenExpired,
  onInactive,
} from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import useMessagesHandler from 'src/hooks/use-messages-handler';
import useMessagesSocket from 'src/hooks/use-messages-socket';
import { usePersistPaletteLocalStorage } from 'src/hooks/use-persist-palette';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import { useNavigateToCategory } from 'src/hooks/useNavigateToCategory';
import { getPallete } from 'src/services/configsApi';
import { getMessages } from 'src/services/gameApi';
import PersisterService from 'src/services/PersisterService';
import { verifyEmail } from 'src/services/userApi';
import { setDeepLink, setFinalTourInfo, setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { setCategoriesData, setPageConfigs } from 'src/store/configs/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { setLogout, setPhoneCode } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import { DEFAULT_INACTIVITY_TIME } from 'src/utils/constants';
import GeneralLoaderPage from '../GeneralLoaderPage';
import LoginPage from '../LoginPage';
import './styles.scss';

export const Main: FC = () => {
  const navigate = useNavigate();
  const device = detectDevice();

  usePersistPaletteLocalStorage();
  useMessagesSocket();
  useMessagesHandler();

  const { search, pathname } = useLocation();

  const casinoTournamentIframe: any = document.querySelector('iframe#tournament');
  const params = getFromQueryParams(search);

  const _setFinalTourInfo = useConnectedAction(setFinalTourInfo.req);
  const _logout = useConnectedAction(setLogout);
  const _setCategoriesData = useConnectedAction(setCategoriesData.req);
  const _setPageConfigs = useConnectedAction(setPageConfigs.req);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _setDeepLink = useConnectedAction(setDeepLink);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setPhoneCode = useConnectedAction(setPhoneCode);
  const _openDialog = useConnectedAction(openDialog);

  const { generalLoading } = useSelector((state: RootState) => state.app);
  const { user, forceLogout } = useSelector((state: RootState) => state.user);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const [inactivityModalVisibility, setInactivityModalVisibility] = useState<boolean>(false);
  const [verificationModalVisibility, setVerificationModalVisibility] = useState<boolean>(false);
  const [forceLogoutModalVisibility, setForceLogoutModalVisibility] = useState<boolean>(false);
  const [activityTrigger, setActivityTrigger] = useState(false);
  const [openTournamentByHostInitativeFlag, setOpenTournamentByHostInitativeFlag] = useState<boolean>(false);

  const navigateToCategory = useNavigateToCategory();

  const gameBox = useMemo(() => {
    if (user && process.env.REACT_APP_GAME_BOX) {
      return <GameBox />;
    }
    return null;
  }, [user]);
  const gameBoxMessaging = useMemo(() => {
    if (user && process.env.REACT_APP_GAME_BOX_MESSAGING_MODULE) {
      return <GameBoxMessaging />;
    }
    return null;
  }, [user]);

  const loginPage = useMemo(() => {
    if (envBoolean(process.env.REACT_APP_HAS_LOGIN_PAGE as string) && !user) {
      return <LoginPage />;
    }
    return <></>;
  }, [user]);

  const handleStorageMessages = (event: any): void => {
    if (event.key === 'sessionId' && event.oldValue && !event.newValue) {
      _logout();
    }
  };

  const toggleInactivityVisibility = (): void => {
    setInactivityModalVisibility(!inactivityModalVisibility);
  };

  const toggleVerficationVisibility = (): void => {
    setVerificationModalVisibility(!verificationModalVisibility);
  };

  const toggleForceLogoutModalVisibility = (): void => {
    setForceLogoutModalVisibility(!forceLogoutModalVisibility);
  };

  const handleInactivityLogout = (): void => {
    _logout({
      onSuccessCb: () => {
        window.location.href = '/';
        toggleInactivityVisibility();
        _setPageConfigs();
      },
    });
  };

  const clearBannersFromStorage = (): void => {
    sessionStorage.removeItem('homeGrid');
    sessionStorage.removeItem('banners');
  };

  const navigateToDeepLinkReg = (): void => {
    if (params.affiliateId) {
      if (user && isTokenExpired(user?.storedDate)) {
        navigateToCategory();
      } else {
        _openAuthModal('registration');
        _setDeepLink('registrationModal');
      }
    }
  };

  const navigateToDeepLink = (): void => {
    switch (params.navigateTo) {
      case 'register':
        if (!user) {
          _openAuthModal('registration');
        }
        break;
      case 'GoS':
        if (user) {
          if (!pathname.includes('casinoTournament')) {
            navigate(`/category/casinoTournament/${search}`);
            _setSelectedCategory('casinoTournament');
          }
          setOpenTournamentByHostInitativeFlag(true);
        } else {
          _openAuthModal('login');
        }

        break;
      case 'liveChat':
        _setDeepLink('liveChat');
        navigateToCategory();
        break;
      case 'deposit':
        if (user) {
          _setDeepLink('deposit');
          navigateToCategory();
        } else {
          _openAuthModal('login');
          _setDeepLink('deposit');
        }
        break;
      default:
        navigateToCategory();
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const setPalette = (): void => {
    let _res = null;
    let _theme = '';

    if (localStorage.getItem('selectedPalette')) {
      _theme = localStorage.getItem('selectedPalette') as string;
    }

    if (PersisterService.hasPersistValue('palette')) {
      _res = PersisterService.getPersistValue('palette');
      // TO DO change palete name
      const t = _res ? process.env.REACT_APP_DEFAULT_PALLETE : 'dark';
      const palette = Boolean(_theme) ? _res[_theme] : t;
      configsVariablesHandler(palette);
      localStorage.setItem('selectedPalette', Boolean(_theme) ? _theme : Object.keys(_res)[0]);
    } else {
      getPallete().then((res: any) => {
        if (res.result) {
          _res = res.result;
          const palette = Boolean(_theme) ? _res[_theme] : _res[process.env.REACT_APP_DEFAULT_PALLETE as string];
          PersisterService.setPersistValue('palette', res.result);
          configsVariablesHandler(palette);
          localStorage.setItem(
            'selectedPalette',
            Boolean(_theme) ? _theme : (process.env.REACT_APP_DEFAULT_PALLETE as string)
          );
        }
      });
    }
  };

  useEffect(() => {
    if (casinoTournamentIframe && openTournamentByHostInitativeFlag) {
      setTimeout(() => {
        casinoTournamentIframe?.contentWindow.postMessage(
          { type: 'openDialogByHostInitiative', dialogType: 'tournamentRegister', tournamentId: params.tourId },
          '*'
        );
        setOpenTournamentByHostInitativeFlag(false);
      }, 1000);
    }
    window.addEventListener('message', (e) => {
      if (e.data?.name === 'clickAction') {
        setActivityTrigger((prevState) => !prevState);
      }
    });
  }, [casinoTournamentIframe, openTournamentByHostInitativeFlag]);

  useEffect(() => {
    if (user && !inactivityModalVisibility && generalConfigs) {
      onInactive(
        () => setInactivityModalVisibility(true),
        () => setInactivityModalVisibility(false),
        +generalConfigs?.inactivitiTime || DEFAULT_INACTIVITY_TIME
      );
    }
  }, [user, inactivityModalVisibility, generalConfigs, activityTrigger]);

  useEffect(() => {
    if (process.env.REACT_APP_MESSAGES_URL) {
      if (user) {
        getMessages(user.id).then((res: any) => {
          const messages = getFreeSpinMessages(res?.result?.messages[user.id]);

          if (!!messages?.length) {
            _openDialog({
              dialogType: EnumDialogsKeys.FREE_SPINS,
              dialogProps: { data: messages[0] },
            });
          }
        });

        (window as any)._smartico_user_id = user.id;
        (window as any)._smartico_language = localStorage.selectedLanguage;
      } else {
        (window as any)._smartico_user_id = null;
        (window as any)._smartico_language = null;
      }
    }
  }, [user]);

  useEffect(() => {
    if (!envBoolean(process.env.REACT_APP_HAS_GENERAL_LOADER as string)) {
      _setPageConfigs();
      _setCategoriesData();
    }

    if (!search.includes('navigateTo') && process.env.REACT_APP_FILE_STORAGE_ADDRESS) {
      _setFinalTourInfo();
    }

    setPalette();

    window.addEventListener('storage', handleStorageMessages);
    return () => window.removeEventListener('storage', handleStorageMessages);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const verificationKey = queryParams.get('verificationKey');

    if (verificationKey) {
      verifyEmail({ key: verificationKey });
      _openDialog({ dialogType: EnumDialogsKeys.SECCESSFULLY_REGED, dialogProps: { data: null } });
    }

    navigateToDeepLink();
    navigateToDeepLinkReg();
    window.onbeforeunload = clearBannersFromStorage;
  }, []);

  useEffect(() => {
    if (!!generalConfigs) {
      document?.body.setAttribute('class', `${device} ${generalConfigs?.template}`);
      _setPhoneCode(generalConfigs?.defaultPhoneCode);
    }
  }, [generalConfigs]);

  useEffect(() => {
    if (localStorage.getItem('user') && isTokenExpirate()) {
      handleInactivityLogout();
    }
  }, [new Date()]);

  useEffect(() => {
    if (!!forceLogout?.message) {
      setForceLogoutModalVisibility(true);
    }
  }, [!!forceLogout?.message]);

  usePostMesssage((v: any) => configsVariablesHandler(v), 'pallete');

  return (
    <>
      {loginPage}
      {envBoolean(process.env.REACT_APP_HAS_GENERAL_LOADER as string) && !generalLoading.includes(false) && (
        <GeneralLoaderPage />
      )}

      <AccountModal />
      {gameBox}
      {gameBoxMessaging}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {user && inactivityModalVisibility && (
        <InactivityModal
          doAction={handleInactivityLogout}
          doActivity={toggleInactivityVisibility}
          open={inactivityModalVisibility}
        />
      )}
      <Dialogs />
      <ErrorMessage />
      <EmailVerificationModal open={verificationModalVisibility} onCancel={toggleVerficationVisibility} />
      <ForceLogout open={forceLogoutModalVisibility} onClose={toggleForceLogoutModalVisibility} />
      <Routing />
    </>
  );
};
