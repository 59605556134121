import React, { FC } from 'react';
import { Components } from 'src/configs/components/components';
import '../styles.scss';

type Props = {
  settings: {
    firstSection: { [key: string]: any };
    secondSection: { [key: string]: any };
    thirdSection: { [key: string]: any };
  };
};
const CategoryPageConstructor: FC<Props> = ({ settings }) => {
  const renderCategoryPageWidgets = (line: any): JSX.Element => {
    const orderIndex = {};

    Object.keys(line).forEach((key: string) => (orderIndex[`${key}`] = line[key].priority));

    const widgetLine = Object.keys(line)
      .sort((a: string, b: string) => orderIndex[a] - orderIndex[b])
      .filter((key: string) => key !== 'styles')
      .map((key: string, index: number) => {
        return React.createElement(Components[key], {
          settings: line[key],
          key: `${key}_${index}`,
        });
      });

    return <>{widgetLine}</>;
  };

  return (
    <div className={'page_container'}>
      <div className={'page_container_first_section'}>{renderCategoryPageWidgets(settings?.firstSection)}</div>
      <div className={'page_container_second_section'} style={settings?.secondSection.styles}>
        {renderCategoryPageWidgets(settings?.secondSection)}
      </div>
      <div className={'page_container_third_section'}>{renderCategoryPageWidgets(settings?.thirdSection)}</div>
    </div>
  );
};
export default CategoryPageConstructor;
