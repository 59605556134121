import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { MediaManager } from 'src/manager/MediaManager';
import { NavigationItem } from 'src/types/res-dto/categories-res';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
    storage: string;
  };
};
const ProviderList: FC<Props> = ({ settings }) => {
  const { styles } = settings;

  const { categoriesData, navigation } = useSelector((state: RootState) => state?.configs);

  const providers = useMemo(() => {
    const categories = navigation?.filter((f) => f?.type === 'category');

    const providerList: Set<string> = new Set();

    categories?.map((f: NavigationItem) => {
      categoriesData[f?.name] && categoriesData[f?.name]?.providers?.forEach((p) => providerList?.add(p));
    });

    return providerList;
  }, [categoriesData]);

  return (
    <div className="providerList_container" style={styles}>
      {Array?.from(providers)?.map((provider: string, i: number) => {
        return (
          <div className="provider" key={provider + i}>
            <SvgIcon
              src={MediaManager.getSrcFromGeneralMediaAssets(
                `${provider.toLowerCase()}.svg`,
                `/providerIcons/BlackMode/`
              )}
            />
          </div>
        );
      })}
    </div>
  );
};
export default ProviderList;
