/* eslint-disable sonarjs/cognitive-complexity */
import cn from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { Loader } from 'src/components/common/Loader';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { configureRedirectWindow } from 'src/helpers/transformers';
import { getTransactionData, isPaymentDataValid, reachTextParser } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { setTransactions, setTrasactionNotification } from 'src/store/transactions/actions';
import { RootState } from 'src/types/store-types';
import GroupedPayments from './components/GroupedPayments';
import GroupedPayment from './components/GroupedPayments/GroupedPayment';
import PaymentInformation from './components/PaymentInformation';
import QRPopup from './components/QRPopup';
import SuccessMessage from './components/SuccessMessage';
import TabItemTemplate1 from './components/TabItemTemplate1';
import TabItemTemplate2 from './components/TabItemTemplate2';
import { Constructor } from './constructor';
import './styles.scss';

type Props = {
  paymentMethodsData: PaymentMethod[] | null;
  groupedPayments: { [key: string]: PaymentMethod[] } | null;
  method: string;
  view: any;
};
const PaymentView: FC<Props> = ({ groupedPayments, paymentMethodsData, method, view }) => {
  const { locale } = useI18n();
  const { t }: Translation = useTranslation();

  const device = useMemo(() => {
    if (isDesktop) {
      return 'desktop';
    } else {
      return 'mobile';
    }
  }, []);

  const _setTransactions = useConnectedAction(setTransactions.req);
  const _setTrasactionNotification = useConnectedAction(setTrasactionNotification);

  const { user, wallets } = useSelector((state: RootState) => state.user);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);
  const { transactionStatus, transactionNotification, transactionLoading } = useSelector(
    (state: RootState) => state.transactions
  );

  const [selectedPaymentID, setSelectedPaymentID] = useState<number>(1);
  const [selectedPayment, setSelectedPayment] = useState<PaymentMethod | null>(null);
  const [paymentError, setPaymentError] = useState<{ [keys: string]: string } | null>(null);
  const [paymentFields, setPaymentFields] = useState<any>(null);
  const [transactionData, setTransactionData] = useState<any>(null);
  const [redirectWindow] = useState<Window | null>(null);
  const [additionalFields, setAdditionalFields] = useState<any | null>(null);
  const [showTransactionResult, setShowTransactionResult] = useState<boolean>(false);
  const [selectedGroupOfPayment, setSelectedGroupOfPayment] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataIsMissing, setDataIsMissing] = useState<boolean>(false);
  const [paymentInitialFields, setPaymentInitialFields] = useState<Record<string, string>>({});

  const handleFieldChange = useCallback(
    (fieldValue: any, fieldName: string): void => {
      setPaymentError(null);

      const addedFields = selectedPayment?.fields[fieldName]?.addedFields;

      if (addedFields && addedFields[fieldValue]) {
        const _additionalFields = {};

        Object.keys(addedFields[fieldValue]).map((m) => {
          return (_additionalFields[m] = null);
        });
        setAdditionalFields({ [fieldName]: addedFields[fieldValue] });
        setPaymentFields({ ...paymentFields, ..._additionalFields, [fieldName]: fieldValue });
      } else {
        if (
          selectedPayment?.fields?.[fieldName]?.type === 'array' &&
          addedFields &&
          !Object.keys(addedFields).includes(fieldValue)
        ) {
          setAdditionalFields(null);
          const _fields = { ...selectedPayment?.fields };

          Object.keys(_fields).map((m) => (_fields[m] = paymentFields[m]));
          setPaymentFields({
            ..._fields,
            [fieldName]: fieldValue,
          });
        } else {
          setPaymentFields((prevState: any) => {
            if (fieldName !== 'subMethod' && prevState?.hasOwnProperty('subMethod')) {
              setAdditionalFields(null);
              return {
                ...prevState,
                [fieldName]: fieldValue,
              };
            } else {
              return {
                ...prevState,
                ...paymentFields,
                [fieldName]: fieldValue,
              };
            }
          });
        }
      }
    },
    [selectedPayment, paymentFields, additionalFields]
  );

  useEffect(() => {
    if (
      paymentFields &&
      paymentFields.pixType &&
      selectedPayment &&
      (!paymentFields.pixType || selectedPayment.fields.pixType === undefined)
    ) {
      delete paymentInitialFields?.pixType;
      setPaymentFields(paymentInitialFields);
    }
  }, [paymentFields, paymentInitialFields, selectedPayment]);

  const selectPaymentGroup = (group: string): void => {
    setSelectedGroupOfPayment(group);
    setSelectedPaymentID(groupedPayments?.[group][0].id as number);
  };

  const handleSubmit = (): void => {
    if (isPaymentDataValid(selectedPayment, paymentFields, t, wallets, setPaymentError, paymentError)) {
      _setTransactions({ ...transactionData, ...paymentFields });
    }
  };

  const closeNotification = (): void => {
    setShowTransactionResult(false);
  };

  const handlePaymentTabClick = (id: number): void => {
    _setTrasactionNotification(null);
    setSelectedPaymentID(id);
    closeNotification();
  };

  const getNotification = (): JSX.Element => {
    if (showTransactionResult) {
      if (transactionNotification?.message?.responseHandling?.type === 'QRCode') {
        return (
          <QRPopup transactionNotification={transactionNotification} t={t} closeNotification={closeNotification} />
        );
      } else {
        if (transactionNotification.message) {
          return (
            <SuccessMessage transactionNotification={transactionNotification} closeNotification={closeNotification} />
          );
        }
      }
    }
    return <></>;
  };

  const tabs = useCallback(
    (paymentItem: any) => {
      if (groupedPayments) {
        return (
          <TabItemTemplate2
            key={paymentItem.id}
            imgUrl={paymentItem.icons?.[device]?.path}
            name={paymentItem?.name}
            paymentItem={paymentItem}
            selectedPaymentID={selectedPaymentID}
            handlePaymentTabClick={handlePaymentTabClick}
          />
        );
      }

      return (
        <TabItemTemplate1
          key={paymentItem.id}
          imgUrl={paymentItem.icons?.[device]?.path}
          name={paymentItem?.name}
          paymentItem={paymentItem}
          selectedPaymentID={selectedPaymentID}
          handlePaymentTabClick={handlePaymentTabClick}
        />
      );
    },
    [selectedPaymentID, groupedPayments]
  );

  const goBackToGroups = (): void => {
    setSelectedGroupOfPayment('');
  };

  useEffect(() => {
    if (!!(paymentMethodsData as PaymentMethod[])?.length && selectedPayment) {
      setSelectedPayment(
        (paymentMethodsData as PaymentMethod[]).find((el: PaymentMethod) => el.id === selectedPaymentID) ?? null
      );
    }

    setPaymentError(null);
  }, [selectedPaymentID]);

  useEffect(() => {
    if (!!paymentMethodsData?.length) {
      setSelectedPaymentID(paymentMethodsData[0].id);
      setSelectedPayment(
        (paymentMethodsData as PaymentMethod[]).find((el: PaymentMethod) => el.id === paymentMethodsData[0].id) ?? null
      );
    }
  }, [paymentMethodsData]);

  useEffect(() => {
    if (selectedPayment) {
      if (selectedPayment?.fields) {
        const initialFields = {};
        const _addedFields: any = [];

        Object.keys(selectedPayment?.fields).map((m) => {
          if (!!selectedPayment?.fields[m]?.addedFields?.[selectedPayment?.fields[m]?.list?.[0]]) {
            _addedFields.push(selectedPayment?.fields[m]?.addedFields?.[selectedPayment?.fields[m]?.list?.[0]]);
          }

          return (initialFields[m] = null);
        });
        setPaymentInitialFields(initialFields);
        setTransactionData(getTransactionData(selectedPayment, user, method, locale));
        setAdditionalFields(_addedFields.length > 0 ? _addedFields : null);
      } else {
        setTransactionData(getTransactionData(selectedPayment, user, method, locale));
      }
    }
  }, [selectedPayment]);

  useEffect(() => {
    if (transactionStatus && selectedPayment) {
      configureRedirectWindow(transactionStatus, redirectWindow, selectedPayment);
    }
  }, [transactionStatus]);

  useEffect(() => {
    transactionNotification && setShowTransactionResult(true);
  }, [transactionNotification]);

  useEffect(() => {
    if (!!groupedPayments && !isMobileOnly) {
      const _defaultGroup = Object.keys(groupedPayments)[0];
      setSelectedGroupOfPayment(_defaultGroup);
      setSelectedPaymentID(groupedPayments[_defaultGroup][0].id);
    }
  }, [groupedPayments]);

  useEffect(() => {
    return () => {
      setSelectedGroupOfPayment('');
      _setTrasactionNotification(null);
    };
  }, []);

  useEffect(() => {
    if (showTransactionResult) {
      closeNotification();
    }
  }, [view]);

  useEffect(() => {
    setDataIsMissing(paymentMethodsData === null);
    // TO DO replace to normal solution ?
    if (!!paymentMethodsData?.length) {
      setTimeout(() => setIsLoading(false), 1500);
    }
  }, [paymentMethodsData]);

  const _: any = useMemo(() => Object.values(additionalFields || {}), [additionalFields]);

  if (dataIsMissing) {
    return (
      <div className="no_data_container">
        <Typography variant="h5">{t('payment_no_data')}</Typography>
      </div>
    );
  }

  if (isLoading && !dataIsMissing) {
    return (
      <div className="loader_container">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <div className="payment_container">
      <div className="payment_content">
        {!!selectedPayment && !!paymentMethodsData?.length && (
          <div className="payment_inner_content">
            {isMobileOnly && selectedGroupOfPayment && groupedPayments && (
              <GroupedPayment
                group={selectedGroupOfPayment}
                groupedPayments={groupedPayments}
                selectedGroupOfPayment={selectedGroupOfPayment}
              />
            )}
            <div className="tabs_section">
              {isMobileOnly && selectedGroupOfPayment && (
                <SvgIcon src={icons.arrowDown} className="back" onClick={goBackToGroups} />
              )}
              <span className="tabs_section__title">{t('choosePaymentMethod')}</span>
            </div>
            <div className="payment">
              {groupedPayments && (
                <div
                  className={cn('payment--grouped-payments', { ['hide']: isMobileOnly && !!selectedGroupOfPayment })}
                >
                  <GroupedPayments
                    groupedPayments={groupedPayments}
                    selectedGroupOfPayment={selectedGroupOfPayment}
                    selectPaymentGroup={selectPaymentGroup}
                  />
                </div>
              )}
              <div className={cn('input_container')}>
                <div
                  className={cn('tabs_section__items', generalConfigs?.paymentTemplate ?? '', {
                    ['grouped']: groupedPayments,
                  })}
                >
                  {(groupedPayments?.[selectedPayment?.group as any] ?? paymentMethodsData)?.map(
                    (paymentItem: PaymentMethod) => tabs(paymentItem)
                  )}
                </div>
                <div className={cn({ ['grouped_content']: groupedPayments }, 'payment_bottom_content')}>
                  {groupedPayments && (
                    <Typography variant="h6" className={'payment_information_item__name'}>
                      {selectedPayment.name}
                    </Typography>
                  )}
                  <PaymentInformation t={t} selectedPayment={selectedPayment} />
                  {!!selectedPayment?.longDescription?.length && (
                    <div className="payment__pament_rules">{reachTextParser(selectedPayment?.longDescription)} </div>
                  )}
                  <div className={cn('payment_content', { ['grouped']: groupedPayments })}>
                    {selectedPayment?.fields &&
                      Constructor.renderInputs(
                        selectedPayment,
                        handleFieldChange,
                        t,
                        paymentError,
                        setPaymentError,
                        method
                      )}
                    {!!additionalFields &&
                      Constructor.renderAdditionalInputs(
                        _,
                        handleFieldChange,
                        t,
                        // bad solution for pixKey method which returns from BE
                        paymentError?.pixType ? { ...paymentError, pixKey: paymentError.pixType } : paymentError,
                        setPaymentError,
                        selectedPayment.id
                      )}
                  </div>
                </div>
                <div className="payment_submit_button">
                  <Button
                    fontWeight="bold"
                    variant="contained"
                    color="primary"
                    className={`success-filled-btn ${transactionLoading ? 'disabled' : ''}`}
                    onClick={handleSubmit}
                    loading={transactionLoading}
                  >
                    {t(paymentMethodsData[0]?.action)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {getNotification()}
      </div>
    </div>
  );
};
export default PaymentView;
