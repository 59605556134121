import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { SliderDataTransforamtionHelper } from 'src/helpers/SliderDataTransformationHelper';
import { transformBanners } from 'src/helpers/transformers';
import { detectDevice } from 'src/helpers/utils';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { CMSManager } from 'src/manager/CMSManager';
import { getBanners } from 'src/services/dataApi';
import PersisterService from 'src/services/PersisterService';
import { Settings, SliderBanner } from 'src/types/common-types/slider';
import { RootState } from 'src/types/store-types';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Arrows } from '../components/Arrows';
import '../styles.scss';
import CarouselSlideItem from './SlidetItem';

type Props = {
  settings: Settings;
};

const pagination = {
  clickable: true,
  renderBullet: function (index: number, className: string) {
    return '<span class="' + className + '" key="' + index + '"></span>';
  },
};

const BannerSlider: FC<Props> = ({ settings }) => {
  const { cols, rows, type, arrowPlacement, arrowsType } = settings;

  const device = detectDevice();
  const { locale } = useI18n();

  const selectedCategory = useSelector((state: RootState) => state.app.selectedCategory) || 'home';
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const [banners, setBanners] = useState<SliderBanner[][]>([]);
  const [my_swiper, set_my_swiper] = useState<any>({});

  const bannersArray = useMemo(() => {
    if (!!banners[selectedCategory] && !!cols) {
      const _prioritizesBanners = banners[selectedCategory].sort((a: any, b: any) => b?.priority - a?.priority);
      return SliderDataTransforamtionHelper.setSliderItemObj(_prioritizesBanners, 12 / cols);
    }

    return [];
  }, [banners, selectedCategory, cols]);

  const getBannersreq = async (): Promise<void> => {
    const _deviceType = device === 'tablet' ? 'desktop' : device;

    const res: any = await getBanners(_deviceType, locale);
    const _ = transformBanners(
      CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string]?.getStaticBannersData(res.data)
    );
    let _banners = { [locale]: _ };
    if (PersisterService.hasPersistValue('banners')) {
      _banners = { ...PersisterService.getPersistValue('banners'), [locale]: _ };
    }
    PersisterService.setPersistValue('banners', _banners);
    setBanners(_);
  };

  const getBannersData = (): void => {
    if (PersisterService.hasPersistValue('banners') && PersisterService.getPersistValue('banners')?.[locale]) {
      try {
        setBanners(PersisterService.getPersistValue('banners')?.[locale]);
      } catch {
        localStorage.setItem('crashError', sessionStorage.getItem('banners') as string);
        getBannersreq();
      }
    } else {
      getBannersreq();
    }
  };

  useEffect(() => {
    getBannersData();
  }, [device, locale]);

  return (
    <>
      {Boolean(banners[selectedCategory]?.length) && (
        <div className={cn('banner_container', type)}>
          <Swiper
            className="carousel_container"
            slidesPerView={1}
            spaceBetween={24}
            autoplay={{
              delay: 5000,
            }}
            speed={1500}
            slidesPerGroup={1}
            loop
            pagination={pagination}
            navigation={{ nextEl: '.arrow-right', prevEl: '.arrow-left' }}
            modules={[Pagination, Navigation]}
            onInit={(ev: any) => {
              set_my_swiper(ev);
            }}
          >
            {bannersArray?.map((carouselItemArr: any[], i: number) => {
              return (
                <SwiperSlide key={carouselItemArr[0] + i}>
                  <div className="carousel_item_container">
                    {carouselItemArr.map((carouselItemData, j) => {
                      return <CarouselSlideItem cols={cols} rows={rows} key={j} carouselItemData={carouselItemData} />;
                    })}
                  </div>
                </SwiperSlide>
              );
            })}
            {isDesktop && bannersArray.length > 1 && (
              <Arrows
                rows={rows}
                placement={arrowPlacement}
                type={arrowsType}
                template={generalConfigs.template}
                size={cols === 12 ? 'lg' : 'md'}
                nextClick={() => my_swiper?.slideNext()}
                prevClick={() => my_swiper?.slidePrev()}
              />
            )}
          </Swiper>
        </div>
      )}
    </>
  );
};
export default BannerSlider;
