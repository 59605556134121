import { FC, useEffect, useState } from 'react';
import { convertBankListIntoSelectOption, convertIntoSelectOption } from 'src/helpers/transformers';
import { bankRequest } from 'src/services/userApi';
import _styles from './styles.module.scss';

type Props = {
  data: {
    payment: PaymentMethod;
    paymentField: any;
    onchange: (ev: any, field: string) => void;
    t: (arg: string) => string;
    error: { [keys: string]: string } | null;
    setError: (error: any) => void;
  };
};
const Select: FC<Props> = ({ data }) => {
  const { payment, paymentField, onchange, t, setError, error } = data;
  const [isSelected, setIsSeleceted] = useState(false);

  const [options, setOptions] = useState<SelectOptionType[]>([]);

  const selectBank = (ev: SelectOptionType): void => {
    const errors = { ...error };
    delete errors[paymentField];

    setError(errors);
    onchange(ev, paymentField);
  };

  const getBanksData = (): void => {
    const _payment = payment.fields[paymentField];

    bankRequest(_payment.endpoint, payment.methodId).then((res): void => {
      if (res?.success) {
        const _opt = convertBankListIntoSelectOption(res.result, _payment.key, _payment.value);
        setOptions(_opt);
      }
    });
  };

  useEffect(() => {
    if (payment?.fields) {
      if (
        !payment.fields[paymentField]?.list &&
        !payment.fields[paymentField]?.list?.length &&
        payment.fields[paymentField].endpoint
      ) {
        getBanksData();
      } else {
        setOptions(convertIntoSelectOption(payment.fields[paymentField].list));
      }
    }
  }, [payment]);

  useEffect(() => {
    if (options.length && !isSelected) {
      onchange(options[0].value, paymentField);
      setIsSeleceted(true);
    }
  }, [options]);

  return (
    <div className={_styles.input_container}>
      <label>{t(paymentField)}</label>
      <select onChange={(ev: any) => selectBank(ev.target.value)}>
        {options?.map((option: SelectOptionType) => (
          <option key={option.value} value={option.value}>
            {t(option.label)}
          </option>
        ))}
      </select>
    </div>
  );
};
export default Select;
