import { FC } from 'react';
import { SvgIcon } from '../SvgIcon';
import './styles.scss';

type Props = {
  coin: number;
  type: string;
};

const Coins: FC<Props> = ({ coin, type }) => {
  return (
    <div className={'coins_wrapper'}>
      <SvgIcon src={`/images/icons/svg/${type === 'FPP' ? 'coin' : 'gos'}.svg`} />
      {coin.toFixed()}
    </div>
  );
};
export default Coins;
